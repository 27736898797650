angular
    .module('ustreet-app')
    .controller('TicketTransferCtrl', ['$scope', '$location', 'userSvc', '$stateParams', 'ticketTypeSvc', 'serviceHelperSvc', 'locationSvc',
    function ($scope, $location, userSvc, $stateParams, ticketTypeSvc, serviceHelperSvc, locationSvc) {
        $scope.locations = locationSvc.getLocations();
        if ($stateParams.id !== undefined) {


            $scope.item = serviceHelperSvc.IssuedTicket.get({ id: $stateParams.id });
            $scope.item
                  .$promise
                  .then(function () {
                      $scope.ticketType = ticketTypeSvc.getItem($scope.item.TicketTypeId);
                  });
            $scope.users = userSvc.getList();
        }

        $scope.ApplyTransfer = function () {
            var transfer = serviceHelperSvc.IssuedTicket.update($scope.item);
            transfer.$promise.then(function () {
                $location.path('/Inventory');
            });
        };

    }]);