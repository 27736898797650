angular
    .module('ustreet-app')
    .controller('TicketReportCtrl', ['$scope', 'serviceHelperSvc', 'dailyReportSvc', 'ticketTypeSvc', 'paymentTypeSvc', 'userSvc', 'expenseTypeSvc', 'adjustmentTypeSvc',
        function ($scope, serviceHelperSvc, dailyReportSvc, ticketTypeSvc, paymentTypeSvc, userSvc, expenseTypeSvc, adjustmentTypeSvc) {
            $scope.hasRole = app.hasRole;

            $scope.data = [];
            $scope.showInactiveTicketTypes = false;
            $scope.showInactiveLocations = false;


            $scope.ApplyFilters = function () {
                $scope.list = $scope.data.filter(function (row) {
                    return ($scope.showInactiveLocations || row.LocationIsActive) &&
                        ($scope.showInactiveTicketTypes || row.TicketTypeIsActive);
                });
            };


            $scope.loading = true;
            $scope.data = serviceHelperSvc.StaleTicketList.get();
            $scope.data.$promise.then(function (data) {
                $scope.loading = false;
                $scope.ApplyFilters();
            }).catch(function (error) {
                console.log('There was some error');
                console.log(error);
            });

            $scope.ShowDailyReportModal = function (id) {

                $scope.current = dailyReportSvc.getItem(id).then(
                    function (response) {
                        $scope.current = response;
                        angular.forEach(response.Roasters, function (r) {
                            r.StartTime = new Date(r.StartTime);
                            r.EndTime = new Date(r.EndTime);
                        });
                        $scope.viewOnly = ($scope.current.Status !== 'Draft');
                        $scope.show_daily_report_modal = true;
                        //$location.hash('viewStart');
                        $scope.ready = true;

                    }
                );
            };


            //This section is important for properly rendering the modal
            $scope.TicketTypes = ticketTypeSvc.getList();
            $scope.Employees = userSvc.getList();
            $scope.PaymentTypes = paymentTypeSvc.getList();
            $scope.ExpenseTypes = expenseTypeSvc.getList();
            $scope.AdjustmentTypes = adjustmentTypeSvc.getList();
            //end of preparation for the modal.


        }]);
