angular
    .module('ustreet-app')
    .controller(
    'TicketInventoryListCtrl', ['$scope', 'ticketInventorySvc',
        function ($scope, svc) {
            $scope.list = [];

            $scope.hasRole = app.hasRole;

            this.init = function () {
                $scope.list = svc.getList();
                $scope.list.$promise
                    .then(
                    function (data) {
                        // calculate the sum
                        $scope.total_tickets = 0;
                        for (var i = 0; i < $scope.list.length; i++) {
                            $scope.total_tickets += $scope.list[i].Remaining;
                        }

                        $scope.ready = true;
                    }
                );
            };

            this.init();
        }
    ]
);
