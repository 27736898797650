angular
    .module('ustreet-app')
    .controller('LocationReportCtrl', ['$scope', 'locationReportSvc', 'serviceHelperSvc', '$stateParams', '$filter', function($scope, locationReportSvc, serviceHelperSvc, $stateParams, $filter) {

  $scope.isReady = false;

  $scope.SendEmail = function() {
    serviceHelperSvc.Message.sendEmail().$promise.then(function() {
      alert("Message Sent");
    });
  };

  if ($stateParams.id !== undefined) {
    $scope.isReady = false;
    $scope.detail = locationReportSvc.getItem($stateParams.id);

    $scope.detail.$promise.then(function() {

      // prep the chart here.
      var values = [];
      $scope.ticks = [];
      if ($scope.detail.Revenue !== undefined) {

        for (var i = 0; i < $scope.detail.Revenue.length && i < 20; i++) {
          var rev = $scope.detail.Revenue[i];
          values.push([new Date($filter('date')(rev.ReportDate, 'MM-dd-yyyy')), rev.Revenue]);
        }

        $scope.chartdata = [{
          "key": "Daily Income",
          "values": values
        }];
        var xtra = $scope.chartdata;

        $scope.isReady = true;
      }
    });
  } else {
    $scope.locations = locationReportSvc.getList();
    $scope.locations.$promise.then(function() {
      for (var i = 0; i < $scope.locations.length; i++) {
        var loc = $scope.locations[i];
        loc.chart = {
          "title": "Revenue",
          "subtitle": "# of Tickets",
          "ranges": [loc.MinTicketCount, loc.MinTicketCount * 2, loc.MinTicketCount * 4],
          "measures": [loc.RemainingTicketCount],
          "markers": [loc.RemainingTicketCount]
        };
      }
      $scope.filterChanged();
      $scope.isReady = true;
    });

  }

  $scope.filterChanged = function() {
    $scope.filtered_locations = [];

    for (var i = 0; i < $scope.locations.length; i++) {
      if ((!$scope.ShowNonReporting && $scope.locations[i].StartedReporting) || $scope.ShowNonReporting) {
        $scope.filtered_locations.push($scope.locations[i]);
      }
    }

  };

  $scope.xaxisticks = function() {
    return $scope.ticks;
  };

  $scope.xAxisTickFormat_Date_Format = function() {
    return function(d) {
      return d3.time.format('%B %d')(new Date(d));
    };
  };

}]);
