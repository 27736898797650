
angular
    .module('ustreet-app')
    .controller('PaymentTypeCtrl', ['$scope', '$location', '$stateParams', 'paymentTypeSvc',
    function ($scope, $location, $stateParams, paymentTypeSvc) {
        app.bindCrud($location, $scope, $stateParams, paymentTypeSvc, '/Admin/PaymentTypes');
    }]);

angular
    .module('ustreet-app')
    .controller('IncidentTypeCtrl', ['$scope', '$location', '$stateParams', 'incidentTypeSvc',
    function ($scope, $location, $stateParams, incidentTypeSvc) {
        app.bindCrud($location, $scope, $stateParams, incidentTypeSvc, '/Admin/IncidentTypes');
    }]);


angular
    .module('ustreet-app')
    .controller('MyScoreCtrl', ['$scope', '$location', '$stateParams', 'paymentTypeSvc',
    function ($scope, $location, $stateParams, paymentTypeSvc) {
        //bindCrud($location, $scope, $stateParams, paymentTypeSvc, '/Admin/PaymentTypes');
    }]);

angular
    .module('ustreet-app')
    .controller('ExpenseTypeCtrl', ['$scope', '$location', '$stateParams', 'expenseTypeSvc',
    function ($scope, $location, $stateParams, paymentTypeSvc) {
        app.bindCrud($location, $scope, $stateParams, paymentTypeSvc, '/Admin/ExpenseTypes');
    }]);

angular
    .module('ustreet-app')
    .controller('AdjustmentTypeCtrl', ['$scope', '$location', '$stateParams', 'adjustmentTypeSvc',
    function ($scope, $location, $stateParams, paymentTypeSvc) {
        app.bindCrud($location, $scope, $stateParams, paymentTypeSvc, '/Admin/AdjustmentTypes');
    }]);

angular
    .module('ustreet-app')
    .controller('TicketTypeCtrl', ['$scope', '$location', '$stateParams', 'ticketTypeSvc',
    function ($scope, $location, $stateParams, ticketTypeSvc) {
        app.bindCrud($location, $scope, $stateParams, ticketTypeSvc, '/Admin/TicketTypes');
    }]);







