// shared function to simplify the definition of the crud controllers.
app.bindCrud = function($location, $scope, $stateParams, service, url){
    $scope.add = function (item) {
        service.add(item).$promise
            .then(function (data) {
                $location.url(url);
            });
    };

    $scope.edit = function (item) {
        service.edit(item).$promise.then(function (data) {
            $location.url(url);
        });
    };

    init();
    function init() {
        if ($stateParams.id !== 0) {
            $scope.item = service.getItem($stateParams.id);
        }
    }
};