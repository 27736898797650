var EmployeeScheduleModel = function(employee){
  $.extend(this, employee);




  // initiailization routine.
  function initDatesForWorkingHours(days) {
      angular.forEach(days, function (d) {
          d.StartTime = Date.toLocalDate(d.StartTime);
      });
  }

  EmployeeScheduleModel.prototype.init = function(){
    initDatesForWorkingHours(this.Day1);
    initDatesForWorkingHours(this.Day2);
    initDatesForWorkingHours(this.Day3);
    initDatesForWorkingHours(this.Day4);
    initDatesForWorkingHours(this.Day5);
    initDatesForWorkingHours(this.Day6);
    initDatesForWorkingHours(this.Day7);
  };

};
