angular
    .module('ustreet-app')
    .controller('ProfileCtrl', ['$scope', '$location', '$stateParams', 'userSvc', 'locationSvc',
    function ($scope, $location, $stateParams, userSvc, locationSvc) {
        var service = userSvc;

        $scope.show_change_password = app.change_password;
        app.change_password = false;

        $scope.edit = function (item) {
            service.edit(item).$promise.then(function (data) {
                $location.url('/Home');
            }, function (error) {
                $scope.message = error.data.ExceptionMessage;
            });
        };

        init();
        function init() {
            $scope.item = service.getItem(-1);
        }

    }]);
