angular
    .module('ustreet-app')
    .factory('serviceHelperSvc', ['$http', '$resource', function ($http, $resource) {
            var baseUrl = config.apiurl;
            var buildUrl = function (resourceUrl) {
                return baseUrl + resourceUrl;
            };

            return {
                AuthorizationToken: $resource(
                    buildUrl("api/Token"), null, {
                        requestToken: {
                            method: 'POST',
                            headers: {"Content-Type": "application/x-www-form-urlencoded"}
                        }
                    }
                ),
                Logout: $resource(buildUrl("api/Logout"), null, {logOut: {method: 'get', isArray: false}}),
                Account: $resource(buildUrl('api/Account/'), null, {register: {method: 'post'}}),
                UserRole: $resource(buildUrl('api/UserRole/:id'), null, {}),
                CurrentUser: $resource(buildUrl('api/CurrentUser'), null, {get: {isArray: false}}),
                Location: $resource(buildUrl('api/Locations/:locationId'), {locationId: '@Id'}, {'update': {method: 'PUT'}}),
                LocationList: $resource(buildUrl('api/Locations/VanillaList'), null, {'get': {method: 'Get'}}),
                ActiveLocationList: $resource(buildUrl('api/Locations/ActiveVanillaList'), null, {'get': {method: 'Get'}}),
                ShedulableLocationList: $resource(buildUrl('api/Locations/SchedulableList'), null, {'get': {method: 'Get'}}),
                StaleTicketList: $resource(buildUrl('api/StaleTickets'), null, {'get': {method: 'GET', isArray: true}}),

                LocationDay: $resource(
                    buildUrl('api/LocationsDay/:id'), {id: '@id'}, {
                        get: {method: 'GET', isArray: true},
                        put: {method: 'POST'}
                    }
                ),

                UserDay: $resource(
                    buildUrl('api/UserDay/:id'), {id: '@id'}, {
                        get: {method: 'GET', isArray: true},
                        put: {method: 'POST'}
                    }
                ),

                LocationType: $resource(buildUrl('api/LocationType/:id'), {id: '@Id'}, {'update': {method: 'PUT'}}),
                PaymentType: $resource(buildUrl('api/PaymentType/:id'), {id: '@Id'}, {'update': {method: 'PUT'}}),

                ExpenseType: $resource(buildUrl('api/ExpenseType/:id'), {id: '@Id'}, {'update': {method: 'PUT'}}),
                AdjustmentType: $resource(buildUrl('api/AdjustmentType/:id'), {id: '@Id'}, {'update': {method: 'PUT'}}),
                TicketType: $resource(buildUrl('api/TicketType/:id'), {id: '@Id'}, {'update': {method: 'PUT'}}),
                ActiveTicketTypeList: $resource(buildUrl('api/TicketType/ActiveList'), null, {'get': {method: 'Get'}}),
                ServiceType: $resource(buildUrl('api/Service/:id'), {id: '@Id'}, {'update': {method: 'PUT'}}),

                IncidentType: $resource(buildUrl('api/IncidentType/:id'), {id: '@Id'}, {'update': {method: 'PUT'}}),
                Incident: $resource(buildUrl('api/Incident/:id'), {id: '@Id'}, {
                    'update': {method: 'PUT'},
                    'delete': {method: 'DELETE'}
                }),
                DeleteIncident: $resource(buildUrl('api/Incident/Delete'), null, {'delete': {method: 'Get'}}),
                IncidentImages: $resource(buildUrl('api/Incident/Images/:id'), {id: '@Id'}, {
                    'update': {method: 'PUT'},
                    'get': {method: 'GET', async: true}
                }),

                Message: $resource(
                    buildUrl('api/Message/Submit'), null, {
                        send: {method: 'PUT'},
                        sendEmail: {method: 'GET'}
                    }
                ),

                TicketHistory: $resource(
                    buildUrl('api/TicketHistory'), {id: '@Id'}, {
                        get: {
                            method: 'GET',
                            isArray: true
                        }
                    }
                ),
                IssuedTicketHistory: $resource(buildUrl('api/issued/inventory/history'), {}, {
                    get: {
                        method: 'GET',
                        isArray: false
                    }
                }),
                PullIssueBack: $resource(buildUrl('api/Inventory/Pullback'), {}, {get: {method: 'GET'}}),

                Audits: $resource(buildUrl('api/Audits'), {date: '@date'}, {get: {method: 'GET', isArray: true}}),
                SaveAudit: $resource(buildUrl('api/Audits'), {}, {post: {method: 'POST', isArray: false}}),

                VarianceReport: $resource(buildUrl('api/VarianceReport'), {}, {get: {method: 'GET', isArray: true}}),
                RevenueReport: $resource(buildUrl('api/RevenueReport'), {}, {get: {method: 'GET', isArray: true}}),
                DailyCollectionReport: $resource(buildUrl('api/DailyCollectionReport'), {}, {
                    get: {
                        method: 'GET',
                        isArray: true
                    }
                }),

                RevenuePivot: $resource(buildUrl('api/Report/PivotRevenue', {}, {query: {method: 'GET', isArray: true}})),
                AdjustmentPivot: $resource(buildUrl('api/Report/PivotAdjustments', {}, {
                    query: {
                        method: 'GET',
                        isArray: true
                    }
                })),

                EmployeeReport: $resource(buildUrl('api/Employee'), {}, {get: {method: 'GET', isArray: true}}),

                UnAuditedReports: $resource(buildUrl('api/Report/UnAuditedPaperWorks'), {}, {
                    get: {
                        method: 'GET',
                        isArray: true
                    }
                }),

                CashReconciliationSummary: $resource(buildUrl('api/Report/CashReconciliationSummary'), {}, {
                    get: {
                        method: 'GET',
                        isArray: true
                    }
                }),

                ReceiveDetail: $resource(buildUrl('api/ReceivedDetail/:id'), {id: '@Id'}, {'update': {method: 'PUT'}}),
                TicketInventory: $resource(buildUrl('api/TicketInventory/:id'), {id: '@Id'}, {'update': {method: 'PUT'}}),
                IssuedTicket: $resource(buildUrl('api/IssuedTicket/:id'), {id: '@Id'}, {'update': {method: 'PUT'}}),

                Shift: $resource(buildUrl('api/Shift/:id'), {id: '@Id'}, {'update': {method: 'PUT'}}),
                Role: $resource(buildUrl('api/Role/:id'), {id: '@Id'}, {'update': {method: 'PUT'}}),

                User: $resource(buildUrl('api/Users/:id'), {id: '@Id'}, {'update': {method: 'PUT'}}),

                UserList: $resource(buildUrl('api/Users/VanillaList'), {}, {}),
                ActiveUsers: $resource(buildUrl('api/Users/Active'), {}, {}),
                UserListByRole: $resource(buildUrl('api/Users/Active/By/Role'), {}, {}),


                Daily: $resource(buildUrl('api/Daily/:id'), {id: '@Id'}, {'update': {method: 'PUT'}}, {'post': {method: 'POST'}}),
                DailyReport: $resource(buildUrl('api/Daily/Report/:id'), {id: '@Id'}, {'update': {method: 'PUT'}}, {
                    'post': {
                        method: 'POST'
                    }
                }),

                UploadDepositSlip: $resource(buildUrl('api/UploadDepositSlip'),
                     {id: '@Id'},
                    {
                    'update': {
                        method: 'POST',
                        headers: {
                            "Accept": "application/json"
                        }
                    }
                }, {
                    'save': {
                        method: 'POST',
                        headers: {"Content-Type": "multipart/form-data", "Accept": "application/json"}
                    }
                }),
                DailyReportDelete: $resource(buildUrl('api/Daily/Report/Delete/:id'), {id: '@Id'}, {'delete': {method: 'POST'}}),
                CustomDailyReport: $resource(buildUrl('api/Daily/Custom')),
                DailyCreate: $resource(buildUrl('api/Daily/Create')),
                DailySubmit: $resource(buildUrl('api/Daily/Submit'), null, {put: {method: 'PUT'}}),
                DailyReportList: $resource(
                    buildUrl('api/Daily/Get/:id'), {id: '@Id'}, {
                        get: {
                            method: 'GET',
                            isArray: true
                        }
                    }
                ),
                DailyTicketRemove: $resource(buildUrl('api/Daily/RemoveTicket'), null, {remove: {method: 'GET'}}),


                PaymentCategory: $resource(buildUrl('api/PaymentCategory/:id'), {id: '@Id'}, {'update': {method: 'PUT'}}),
                Project: $resource(buildUrl('api/Project/:id'), {id: '@Id'}, {'update': {method: 'PUT'}}),

                ProjectHistory: $resource(buildUrl('api/Project/History'), {}, {get: {method: 'GET', isArray: true}}),

                Payment: $resource(buildUrl('api/Payment/:id'), {id: '@Id'}, {
                    'update': {method: 'PUT'},
                    'query': {method: 'GET', isArray: false},
                    'delete': {method: 'DELETE', isArray: false},
                }),
                InitiateNewPayroll: $resource(buildUrl('api/Payment/NewPayroll'), {}, {
                    get: {
                        method: 'GET',
                        isArray: false
                    }
                }),

                DReport: $resource(buildUrl('api/Report/GetDailySummary'), {}, {
                    get: {
                        method: 'GET',
                        isArray: false,
                        headers: {}
                    }
                }),

                ConfigurationSettings: $resource(
                    buildUrl('api/Settings/:id'), {id: '@Id'}, {
                        get: {
                            method: 'GET',
                            isArray: true
                        }, save: {method: "POST"}
                    }
                ),

                LocationReport: $resource(buildUrl('api/LocationReport/:id', {id: '@id'}))
            };
        }]
    );
