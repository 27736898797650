angular
    .module('ustreet-app')
    .factory('projectSvc', ['$resource', 'serviceHelperSvc', function ($resource, serviceHelper) {
    var Service = serviceHelper.Project;
    var add = function (item) {
        //$resource.save will immediately return an object which will have $promise property.
        //This property will get resolved with values, once the Server returns response
        return Service.save(item);
    };
    var edit = function (item) {
        var updation = Service.update(item);
        return updation;
    };
    var getItem = function (id) {
        return Service.get({ id: id });
    };

    var getHistory = function(id){
      return serviceHelper.ProjectHistory.get({Id: id});
    }

    var getList = function () {
        return Service.query();
    };
    var deleteItem = function (id) {
        return Service.delete({ id: id });
    };

    return {
        add: add,
        edit: edit,
        getItem: getItem,
        getList: getList,
        getHistory: getHistory,
        deleteItem: deleteItem
    };
}]);
