angular
    .module('ustreet-app')
    .controller('IncidentManagementCtrl', ['$scope', '$location', 'incidentSvc', function ($scope, $location, service) {
        $scope.showClosed = false;
        $scope.hasRole = app.hasRole;
        $scope.search = '';
        $scope.items = [];
        $scope.filteredList = [];

        $scope.searchIncidents = function () {
            if ($scope.search === '') {
                $scope.filteredList = $scope.items;
            } else {
                $scope.filteredList = $scope.items.filter(function (incident) {
                    return incident.LocationName.includes($scope.search) ||
                        (incident.CustomerFirstName !== null && incident.CustomerFirstName.includes($scope.search)) ||
                        (incident.CustomerLastName !== null && incident.CustomerLastName.includes($scope.search));
                });
            }
        };

        $scope.init = function () {
            $scope.ready = false;
            $scope.items = service.getList({
                showClosed: $scope.showClosed
            });

            $scope.items.$promise.then(function () {
                var today = new Date();
                for (var i = 0; i < $scope.items.length; i++) {
                    var item = $scope.items[i];
                    if (item.ClosedDate !== undefined && item.ClosedDate !== null) {
                        var range = moment(item.EventDate).twix(moment(item.ClosedDate));
                        item.DateDiff = range.humanizeLength();
                    }
                }
                $scope.searchIncidents();
                $scope.ready = true;
            });
        };

        $scope.deleteIssue = function (id) {
            if (window.confirm('Are you sure you want to delete this?', 'You will not be able to undo deleted incidents.')) {
                $scope.ready = false;
                app.scrollTop();
                var promise = service.deleteItem(id);
                promise.$promise.then(function (data) {
                    $scope.init();
                });
            }
        };

        $scope.init();

    }]);
