angular
    .module('ustreet-app')
    .controller('CashVarianceCtrl', ['$scope','$window', '$location', '$stateParams', '$filter', 'serviceHelperSvc', function ($scope, $window, $location, $stateParams, $filter, serviceHelperSvc) {

    $scope.ChangeParams = function () {

        if ($scope.filter.startDate !== undefined && $scope.filter.lastDate !== undefined) {
            var filter = { startDate: $filter('date')($scope.filter.startDate, 'MM-dd-yyyy'), lastDate: $filter('date')($scope.filter.lastDate, 'MM-dd-yyyy') };
            $scope.loading = true;
            $scope.rows = serviceHelperSvc.VarianceReport.get(filter);
            $scope.rows.$promise.then(function(){
                $scope.loading = false;
            });
        }

    };

    $scope.ExportExcel = function(){
      if ($scope.filter.startDate !== undefined && $scope.filter.lastDate !== undefined) {
          var filter = { startDate: $filter('date')($scope.filter.startDate, 'MM-dd-yyyy'), lastDate: $filter('date')($scope.filter.lastDate, 'MM-dd-yyyy') };
          var param = jQuery.param( filter );
          var url = config.apiurl + 'api/VarianceReport/Excel?' + param;
          $window.open(url,"_BLANK");
      }
    };

}]);
