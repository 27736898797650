function LocationCtrl($scope, $location, $stateParams, locationSvc, locationTypeSvc, $filter) {

    $scope.ready = false;

    $scope.editLocation = function (location) {
        if (location.StartDate === '') {
            $scope.errorMessage = 'Date value is required. Please set it and try again.';
            return;
        }
        location.StartDate = new moment(location.StartDate).format('yyyy-MM-DD');
        //Hack: Angular Binding did not work properly on date input type
        if (location.Id !== undefined) {

            locationSvc.editLocation(location).$promise.then(function () {
                $location.url('/Admin/Locations');
            }).catch(function (error) {
                $scope.errorMessage = 'There is an error saving your request.';
            });
        } else {

            locationSvc.addLocation(location).$promise
                .then(function () {
                    $location.url('/Admin/Locations');
                }).catch(function () {
                $scope.errorMessage = 'There was error saving your request.';
            });
        }

    };

    function init() {
        $scope.locationTypes = locationTypeSvc.getList();
        if ($stateParams.id > 0) {
            $scope.location = locationSvc.getLocation($stateParams.id);
            $scope.location.$promise.then(function () {
                $scope.location.StartDate = $filter('date')($scope.location.StartDate, 'yyyy-MM-dd');
                $scope.ready = true;
            });
        } else {
            $scope.ready = true;
        }
    }

    init();
}


angular
    .module('ustreet-app')
    .component('locationForm', {
        bindings: 'location-form',
        controller: LocationCtrl,
        templateUrl: '/Scripts/app/components/admin/locations/partials/edit.html'
    });

LocationCtrl.$inject = ['$scope', '$location', '$stateParams', 'locationSvc', 'locationTypeSvc'];
