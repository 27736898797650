function LocationDayCtrl($scope, $location, $stateParams, locationSvc, serviceHelper) {

    function init() {
        if ($stateParams.id > 0) {
            $scope.location = locationSvc.getLocation($stateParams.id);
            $scope.days = serviceHelper.LocationDay.get({id: $stateParams.id});

            $scope.days.$promise.then(function (d) {
                angular.forEach($scope.days, function (day) {

                    angular.forEach(day.Shifts, function (shift) {
                        shift.StartHour = Date.toLocalDate(shift.StartHour);
                    });

                });
            });
        }
    }

    init();

    $scope.editDays = function () {
        serviceHelper.LocationDay.put($scope.location.Id, $scope.days)
            .$promise
            .then(function (data) {
                $location.path('/Admin/Locations');
            }, function (error) {
                console.info('Your changes have not been saved. ');
            });
    };

}

angular
    .module('ustreet-app')
    .component('locationDay', {
        bindings: 'locationDay',
        controller: LocationDayCtrl,
        templateUrl: '/Scripts/app/components/admin/locations/partials/days.html',
    });

LocationDayCtrl.$inject = ['$scope', '$location', '$stateParams', 'locationSvc', 'serviceHelperSvc']