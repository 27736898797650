angular
    .module('ustreet-app')
    .controller('EmployeePreferenceCtrl', ['$scope', 'serviceHelperSvc', '$stateParams', '$filter', function ($scope, serviceHelperSvc, $stateParams, $filter) {

  $scope.isReady  = false;
  
  $scope.list = serviceHelperSvc.UserList.query();
  $scope.list.$promise.then(function(data){
    $scope.isReady = true;
  });
  
}]);