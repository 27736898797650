angular
    .module('ustreet-app')
    .factory(
    'scheduleService', ['$http', '$resource', function ($http, $resource) {
        var baseUrl = config.apiurl;
        var buildUrl = function (resourceUrl) {
            return baseUrl + resourceUrl;
        };

        return {
            Schedule: $resource(
                buildUrl("api/Schedules/Create/:date"), { date: '@date' }, {
                    create: { method: 'GET' },
                    save: { method: 'POST' }
                }
            ),
            Finalizer: $resource(buildUrl("api/Schedules/Finalize"), {}, { finalize: { method: 'GET' } }),
            ByEmployee: $resource(
                buildUrl('api/Schedules/ByEmployee/:id'), { id: '@id' }, {
                    get: {
                        method: 'POST',
                        isArray: false
                    }
                }
            ),
            Locations: $resource(
                buildUrl('api/Schedules/Locations/:id'), { id: '@id' }, {
                    get: {
                        method: 'POST',
                        isArray: true
                    }
                }
            ),
            MySchedule: $resource(buildUrl("api/Schedules/My"), {}, { get: { method: 'GET', isArray: false } }),
            MyEmployee: $resource(buildUrl("api/Schedules/MyEmployee"), {}, { get: { method: 'POST', isArray: false } }),
            MyLocation: $resource(buildUrl("api/Schedules/MyLocation"), {}, { get: { method: 'POST', isArray: false } }),
            Weeks: $resource(buildUrl("api/Schedules/Weeks"), {}, { get: { method: 'POST', isArray: true } })
        };
    }]
);
