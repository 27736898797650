angular
    .module('ustreet-app')
    .controller('UserCtrl', ['$scope', '$location', '$stateParams', 'userSvc', 'locationSvc','mainSvc',
    function ($scope, $location, $stateParams, userSvc, locationSvc, mainSvc) {
        let url = '/Admin/Users';
        $scope.locations = locationSvc.getLocations();

        $scope.hasRole = app.hasRole;

        $scope.add = function (item) {
            userSvc.add(item).$promise
            .then(function (data) {
                $location.url(url);
            });
        };

        $scope.edit = function (item) {
            userSvc.edit(item).$promise.then(function (data) {
                $location.url(url);
            });
        };

        $scope.register = function (userRegistration) {
            if (userRegistration.password !== userRegistration.confirmPassword) {
                $scope.errorMessage = "Passwords do not match";
                return;
            }

            $scope.errorMessage = '';

            mainSvc.registerUser(userRegistration).$promise
                .then(function (data) {
                    $location.url(url);
                }).catch(function (error) {
                    if (error.status === 400) {
                        $scope.errorMessage = "Email / phone number already exists.";
                    }
                    else {
                        $scope.errorMessage = "An error occurred while performing this action. Please try after some time.";
                    }
                });
        };
        function init() {
            if ($stateParams.id !== 0 && $stateParams.id !== undefined) {
                $scope.item = userSvc.getItem($stateParams.id);
            }
            $('.ui.checkbox')
            .checkbox();
        }
        init();


    }]);
