angular
    .module('ustreet-app')
    .controller('ForgotCtrl', ['$scope', function ($scope) {
    
    $scope.forgotPasswordClicked = function(form){
        if($scope.isValid()){
           
        }
    };
     
    $scope.isValid = function(){
        return (!angular.isUndefined($scope.forgotPassword) && (!angular.isUndefined($scope.forgotPassword.Email) || !angular.isUndefined($scope.forgotPassword.Phone)));
    };
    
    // check if the form is valid on load.
    $scope.isValid();
}]);