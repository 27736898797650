angular
    .module('ustreet-app')
    .controller('DailyReportCreateCtrl', ['$scope', 'ticketTypeSvc', 'adjustmentTypeSvc', 'expenseTypeSvc', 'userSvc', 'paymentTypeSvc', 'dailyReportSvc', '$stateParams', '$location', '$window',
        function ($scope, ticketTypeSvc, adjustmentTypeSvc, expenseTypeSvc, userSvc, paymentTypeSvc, dailyReportSvc, $stateParams, $location, $window) {

            // prep the whole wizard,
            // this is where the life of the application starts.
            $scope.ready = false;

            $scope.current = dailyReportSvc
                .getItem($stateParams.id)
                .then(
                    function (data) {
                        $scope.current = data;
                        // angular.forEach(data.Roasters, function (r) {
                        //     r.StartTime = new Date(r.StartTime);
                        //     r.EndTime = new Date(r.EndTime);
                        // });
                        $scope.ready = true;
                        $scope.viewOnly = ($scope.current.Status !== 'Draft');
                        if ($scope.hasRole('ADMIN')) {
                            $scope.viewOnly = false;
                        }
                    },
                    function (data) {
                        // on error
                        $window.history.back();
                    }
                );


            $scope.deleteFullReport = function () {
                var locationId = $scope.current.LocationId;
                var deleteTask = dailyReportSvc.deleteItem($scope.current.Id);
                deleteTask.$promise.then(function (data) {
                    $location.path('/Home');
                });
            };

            $scope.hasRole = app.hasRole;

            $scope.FilterObject = function (arr, id) {
                return _.findWhere(arr, {'Id': parseInt(id)});
            };


            $scope.scrollTop = app.scrollTop;
            $scope.TicketTypes = ticketTypeSvc.getList();
            $scope.Employees = userSvc.getList();
            $scope.PaymentTypes = paymentTypeSvc.getList();
            $scope.ExpenseTypes = expenseTypeSvc.getList();
            $scope.AdjustmentTypes = adjustmentTypeSvc.getList();

            $scope.compareNumbers = function (val1, val2) {
                if (angular.isUndefined(val1) || angular.isUndefined(val2)) {
                    return false;
                }
                return (Number(val1) === Number(val2));
            };
            // step one
            //
            $scope.TicketBeginningBalance = function () {
                var total = 0;
                angular.forEach($scope.current.DailyTickets, function (recipt) {
                    if (angular.isNumber(recipt.EndTicketNumber - recipt.StartTicketNumber)) {
                        total = total + recipt.EndTicketNumber - recipt.StartTicketNumber;
                    }
                });
                return total;
            };

            $scope.RemainingTickets = function () {
                var total = 0;
                angular.forEach($scope.current.DailyTickets, function (recipt) {
                    if (angular.isNumber(recipt.EndTicketNumber - recipt.ClosingTicketNumber)) {
                        total = total + recipt.EndTicketNumber - recipt.ClosingTicketNumber;
                    }
                });
                return total;
            };


            $scope.IsTicketInfoValid = function () {
                $scope.IsStepOneValid = true;
                angular.forEach($scope.current.DailyTickets, function (recipt) {
                    if (angular.isNumber(recipt.EndTicketNumber) && angular.isNumber(recipt.ClosingTicketNumber) && angular.isNumber(recipt.StartTicketNumber)) {
                        var total = recipt.EndTicketNumber < recipt.StartTicketNumber;
                        var start = recipt.ClosingTicketNumber < recipt.StartTicketNumber;
                        var end = recipt.EndTicketNumber < recipt.ClosingTicketNumber;
                        if (total || start || end) {
                            $scope.IsStepOneValid = false;
                        }
                    } else {
                        $scope.IsStepOneValid = false;
                    }
                });
                return $scope.IsStepOneValid;
            };

            $scope.OnTicketInfoChanged = function () {
                $scope.IsTicketInfoValid();
            };

            // validate whatever has been loaded on memory
            $scope.OnTicketInfoChanged();

            $scope.TotalIssuedTicket = function () {
                return $scope.TicketBeginningBalance() - $scope.RemainingTickets();
            };

            // End of step one
            $scope.OnSales = function () {
                console.warn("Sales Changed to " + $scope.TotalCashSales());
            };

            $scope.TotalAdjustedTickets = function () {
                if ($scope.current.DailyAdjustments === undefined) {
                    return 0;
                }

                var total = $scope.current.DailyAdjustments.sum("TicketCount");
                return total;
            };

            $scope.TotalSoldTicket = function () {
                if ($scope.current.DailySales === undefined) {
                    return 0;
                }

                return $scope.current.DailySales.sum("TicketCount");
            };

            $scope.TotalSales = function () {
                if ($scope.current.DailySales === undefined) {
                    return 0;
                }

                return $scope.current.DailySales.map((recipt) => (Number(recipt.TicketCount) * Number(recipt.Rate)))
                    .reduce((a, b) => a + b, 0);
            };

            $scope.TotalCashSales = function () {

                if ($scope.current.DailySales === undefined) {
                    return 0;
                }

                return $scope.current.DailySales.filter((recipt) => (recipt.PaymentTypeId === 1))
                    .map((recipt) => (recipt.TicketCount * recipt.Rate))
                    .reduce((a, b) => a + b, 0);
            };

            $scope.onFileChanged = function () {
                console.log("File changed");
                console.log($scope.current.file);
            };
            window.dailyReportSvc = dailyReportSvc;
            $scope.SelectFile = function (event) {
                const file = event.target.files[0];
                let reader = new FileReader();
                reader.onload = function (e) {
                    $scope.NewImage = e.target.result;

                    dailyReportSvc.uploadDepositSlip($scope.current.Id, {
                        Image: e.target.result,
                        OriginalFileName: file.name,
                        Id: $scope.current.Id
                    });
                    $scope.$apply();
                };
                reader.readAsDataURL(file);

            };

            $scope.TotalExpenses = function () {
                if ($scope.current.DailyExpenses === undefined) {
                    return 0;
                }

                let total = 0;
                for (const expense of $scope.current.DailyExpenses) {
                    total = total + (expense.Quantity * expense.UnitPrice);
                }
                return total;
            };

            $scope.OnBeginningTicketNumberChanged = function (recipt) {
                recipt.EndTicketNumber = recipt.StartTicketNumber + 1;
                recipt.ClosingTicketNumber = recipt.StartTicketNumber;
                $scope.$emit('IsTicketInfoValid');
            };

            // last step
            $scope.finishedWizard = function () {
                $scope.processing = true;
                dailyReportSvc.edit($scope.current)
                    .then(function (data) {
                        $location.path('/Daily/' + data.LocationId);
                    }, function (error) {

                        $scope.error = error.statusText;
                        $scope.processing = false;
                    });

            };

            $scope.postWizard = function () {
                $scope.processing = true;
                $scope.current.Status = "Submitted";
                dailyReportSvc.edit($scope.current).then(function (data) {
                    $location.path('/Daily/' + data.LocationId);
                }, function (error) {
                    $scope.error = error.statusText;
                    $scope.processing = false;
                });
            };

            $scope.RemoveTicket = function (ticket, index) {
                $scope.ticketToDelete = ticket;
                $scope.indexToDelete = index;
                $scope.show_remove_ticket_modal = true;
            };

            $scope.RemoveTicketConfirmed = function () {
                const remove = dailyReportSvc.removeTicket({Id: $scope.ticketToDelete.Id, ReportId: $scope.current.Id});
                remove.$promise.then(function () {
                    $scope.current.DailyTickets.splice($scope.indexToDelete, 1);
                });
                $scope.show_remove_ticket_modal = false;
            };

            $scope.CloseModal = function () {
                $scope.show_remove_ticket_modal = false;
            };
        }]);
