angular
    .module('ustreet-app')
    .config(['$stateProvider', function ($stateProvider) {
        $stateProvider.state('/PaymentCategory', {
            url: '/PaymentCategory',
            templateUrl: '/Scripts/app/views/payment/category/index.html',
            controller: 'PaymentCategoriesCtrl'
        });
        $stateProvider.state('/PaymentCategory/Add', {
            url: '/PaymentCategory/Add',
            templateUrl: '/Scripts/app/views/payment/category/add.html',
            controller: 'PaymentCategoryCtrl'
        });
        $stateProvider.state('/PaymentCategory/Edit', {
            url: '/PaymentCategory/Edit/:id',
            templateUrl: '/Scripts/app/views/payment/category/edit.html',
            controller: 'PaymentCategoryCtrl'
        });
        $stateProvider.state('/Project', {
            url: '/Project',
            templateUrl: '/Scripts/app/views/payment/projects/index.html',
            controller: 'ProjectsCtrl'
        });
        $stateProvider.state('/Project/Add', {
            url: '/Project/Add',
            templateUrl: '/Scripts/app/views/payment/projects/add.html',
            controller: 'ProjectCtrl'
        });
        $stateProvider.state('/Project/Edit', {
            url: '/Project/Edit/:id',
            templateUrl: '/Scripts/app/views/payment/projects/edit.html',
            controller: 'ProjectCtrl'
        });
        $stateProvider.state('/Project/History', {
            url: '/Project/History/:id',
            templateUrl: '/Scripts/app/views/payment/projects/history.html',
            controller: 'ProjectHistoryCtrl'
        });
        $stateProvider.state('/Payments', {
            url: '/Payments',
            component: 'paymentList'
        });
        $stateProvider.state('/Payments/Add', {
            url: '/Payments/Add',
            component: 'paymentForm'
        });
        $stateProvider.state('Payments/Edit', {
            url: '/Payments/Edit/{id}',
            component: 'paymentForm'
        });
    }]);