angular
    .module('ustreet-app')
    .factory('currentUserService', ['serviceHelperSvc', function(serviceHelperSvc){
  var getCurrentUser =  function(){
    return serviceHelperSvc.CurrentUser.get();
  };
  
  return {
    get: getCurrentUser
  };
}]);


































