angular
    .module('ustreet-app')
    .factory('locationSvc', ['$resource', 'serviceHelperSvc', function ($resource, serviceHelper) {
    var Location = serviceHelper.Location;
    var LocationList = serviceHelper.LocationList;

    var addLocation = function (location) {
        //$resource.save will immediately return an object which will have $promise property. 
        //This property will get resolved with values, once the Server returns response
        return Location.save(location);
    };
    var editLocation = function (location) {
        var updation = Location.update(location);
        return updation;
    };
    var getLocation = function (id) {
        return Location.get({ locationId: id });
    };
    var getLocations = function () {
        return LocationList.query();
    };

    var getActiveLocations = function () {
        return serviceHelper.ActiveLocationList.query();
    };
    var getLocationsFull = function () {
        return Location.query();
    };

    var deleteLocation = function (locationId) {
        return Location.delete({ locationId: locationId });
    };

    var geScheduledLocations = function () {
        return serviceHelper.ShedulableLocationList.query();
    };

    return {
        addLocation: addLocation,
        editLocation: editLocation,
        getActiveLocations: getActiveLocations,
        getLocation: getLocation,
        getLocations: getLocations,
        getLocationsFull: getLocationsFull,
        deleteLocation: deleteLocation,
        getScheduledLocations: geScheduledLocations

    };
}]);