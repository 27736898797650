
angular
    .module('ustreet-app')
    .controller(
    'ShiftsCtrl', ['$scope', 'shiftSvc',
        function ($scope, shiftSvc) {
            $scope.list = [];

            this.init = function () {
                $scope.list = shiftSvc.getList();
            };

            this.init();
        }
    ]
);