angular
    .module('ustreet-app')
    .controller(
    'ConfigurationCtrl', ['$scope', 'serviceHelperSvc',
        function ($scope, serviceHelperSvc) {
            $scope.list = [];

            this.init = function () {
                $scope.list = serviceHelperSvc.ConfigurationSettings.get();
            };

            $scope.Save = function () {
                var submit = serviceHelperSvc.ConfigurationSettings.save($scope.list);
                submit.$promise.then(
                    function (data) {
                        $scope.message = "Settings Saved";
                    }
                );
            };

            this.init();
        }
    ]
);