angular
    .module('ustreet-app')
    .controller('TicketInventoryIssueCtrl', ['$scope', '$location', '$stateParams', 'ticketInventorySvc', 'ticketTypeSvc', 'userSvc', 'issuedTicketSvc', 'locationSvc',
    function ($scope, $location, $stateParams, svc, ticketTypeSvc, userSvc, issuedTicketSvc, locationSvc) {

        $scope.ticketTypes = ticketTypeSvc.getActiveList();

        if ($stateParams.id !== undefined) {
            $scope.users = userSvc.getList();
            $scope.inventory = svc.getItem($stateParams.id);

            $scope.locations = locationSvc.getActiveLocations();

            $scope.inventory.$promise.then(function () {
                $scope.item = {
                    IssuedTicketId: $scope.inventory.Id,
                    StartNumber: $scope.inventory.ClosingNumber,
                    TicketTypeId: $scope.inventory.TicketTypeId
                };
                console.log($scope.item);
            });

            $scope.issue = function (item) {
                issuedTicketSvc.add(item).$promise
                    .then(function (data) {
                        $location.url('/Inventory');
                    });
            };
        }

    }]);
