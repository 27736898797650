window.app = angular.module('ustreet-app',
    [
        'ngSanitize',
        'ui.router',
        'ngCookies',
        'ngResource',
        'ngAnimate',
        'angularify.semantic.popup',
        'angularify.semantic.checkbox',
        'angularify.semantic.modal',
        'ngStorage',
        'angularify.semantic.dropdown',
        'smart-table',
        'ng-fusioncharts',
        'ngDraggable',
        'mgo-angular-wizard',
        'LocalStorageModule',
        // 'mgcrea.ngStrap',
        'matchmedia-ng',
        '720kb.datepicker'
    ]);

angular
    .module('ustreet-app')
    .config(['$stateProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider', '$qProvider',
        function ($stateProvider, $urlRouterProvider, $locationProvider, $httpProvider, $qProvider) {
            $httpProvider.defaults.useXDomain = true;
            $httpProvider.defaults.withCredentials = false;
            delete $httpProvider.defaults.headers.common['X-Requested-With'];
            $httpProvider.defaults.useXDomain = false;
            $locationProvider.html5Mode(false);
            $qProvider.errorOnUnhandledRejections(false);

            $stateProvider
                .state('login', {
                    url: '/Login',
                    redirectTo: function () {
                        window.location.replace('/login.html#/');
                    }
                });
            $stateProvider.state('/Home', {
                url: '/Home',
                templateUrl: '/Scripts/app/views/home/home.html',
                controller: 'HomeCtrl'
            });

            $stateProvider.state('/Forgot/Password', {
                url: '/Forgot/Password',
                templateUrl: '/Scripts/app/views/home/Forgot.html',
                controller: 'ForgotCtrl'
            });

            $stateProvider.state('/About', {
                url: '/About',
                templateUrl: '/Scripts/app/views/about/About.html'
            });
            $stateProvider.state('/Resources', {
                url: '/Resources',
                templateUrl: '/Scripts/app/views/resources/Resources.html',
                controller: 'ResourcesCtrl'
            });
            $stateProvider.state('/Resources/Add', {
                url: '/Resources/Add',
                templateUrl: '/Scripts/app/views/resources/add.html',
                controller: 'ResourceCtrl'
            });
            $stateProvider.state('/Resources/Edit', {
                url: '/Resources/Edit/{resourceId}',
                templateUrl: '/Scripts/app/views/resources/edit.html',
                controller: 'ResourceEditCtrl'
            });
            $stateProvider.state('/Resources/Details', {
                url: '/Resources/{resourceId}',
                templateUrl: '/Scripts/app/views/resources/details.html',
                controller: 'ResourceCtrl'
            });
            $stateProvider.state('/Activities/Add', {
                url: '/Activities/Add',
                templateUrl: '/Scripts/app/views/activities/add.html',
                controller: 'ActivityAddCtrl'
            });

            $stateProvider.state('/Profile', {
                url: '/Profile',
                templateUrl: '/Scripts/app/views/home/profile.html',
                controller: 'ProfileCtrl'
            });
            $stateProvider.state('/Error', {
                url: '/Error',
                templateUrl: '/Scripts/app/views/shared/error.html'
            });
            $stateProvider.state('/Construction', {
                url: '/Construction',
                templateUrl: '/Scripts/app/views/shared/under-construction.html'
            });
            $stateProvider.state('/Inventory', {
                url: '/Inventory',
                templateUrl: '/Scripts/app/views/inventory/index.html',
                controller: 'TicketInventoryListCtrl'
            });
            $stateProvider.state('/Inventory/Add', {
                url: '/Inventory/Add',
                templateUrl: '/Scripts/app/views/inventory/tickets/add.html',
                controller: 'TicketInventoryCtrl'
            });
            $stateProvider.state('/Inventory/Edit', {
                url: '/Inventory/Edit/:id',
                templateUrl: '/Scripts/app/views/inventory/tickets/add.html',
                controller: 'TicketInventoryCtrl'
            });
            $stateProvider.state('/Inventory/Issue', {
                url: '/Inventory/Issue/:id',
                templateUrl: '/Scripts/app/views/inventory/tickets/issue.html',
                controller: 'TicketInventoryIssueCtrl'
            });
            $stateProvider.state('/Inventory/History', {
                url: '/Inventory/History/:id',
                templateUrl: '/Scripts/app/views/inventory/tickets/history.html',
                controller: 'TicketHistoryCtrl'
            });
            $stateProvider.state('/Inventory/Transfer', {
                url: '/Inventory/Transfer/:id',
                templateUrl: '/Scripts/app/views/inventory/tickets/transfer.html',
                controller: 'TicketTransferCtrl'
            });
            $stateProvider.state('/Daily', {
                url: '/Daily',
                templateUrl: '/Scripts/app/views/daily/index.html',
                controller: 'DailyReportCtrl',
                resolve: {
                    'locations': ['locationSvc', function (locationSvc) {
                        return locationSvc.getLocations();
                    }]
                }
            });
            $stateProvider.state('/MyScore', {
                url: '/MyScore',
                templateUrl: '/Scripts/app/views/score/index.html',
                controller: 'MyScoreCtrl'
            });
            $stateProvider.state('Daily.Detail', {
                url: '/Daily/{id}',
                templateUrl: '/Scripts/app/views/daily/index.html',
                controller: 'DailyReportCtrl',
                resolve: {
                    'locations': ['locationSvc', function (locationSvc) {
                        return locationSvc.getLocations();
                    }]
                }
            });
            $stateProvider.state('/Daily/Wizard', {
                url: '/Daily/Wizard/{id}',
                templateUrl: '/Scripts/app/views/daily/wizard.html',
                controller: 'DailyReportCreateCtrl'
            });
            $stateProvider.state('/Daily/View', {
                url: '/Daily/View/:id',
                templateUrl: '/Scripts/app/views/daily/view.html',
                controller: 'DailyReportCreateCtrl'
            });
            $stateProvider.state('/Cash', {
                url: '/Cash',
                templateUrl: '/Scripts/app/views/audit/cash/manage.html',
                controller: 'CashManagementCtrl'
            });

            $stateProvider.state('/Cash/Report', {
                    url: '/Cash/Report',
                    templateUrl: '/Scripts/app/views/audit/cash/view-cash.html',
                    controller: 'CashManagementCtrl'
                });

            $stateProvider.state('/Cash/Variance', {
                    url: '/Cash/Variance',
                    templateUrl: '/Scripts/app/views/audit/variance/index.html',
                    controller: 'CashVarianceCtrl'
                });
            $stateProvider.state('/Cash/Revenue', {
                url: '/Cash/Revenue',
                templateUrl: '/Scripts/app/views/audit/revenue/index.html',
                controller: 'CashRevenueCtrl'
            });
            $stateProvider.state('/Cash/Daily/Audited', {
                url: '/Cash/Daily/Audited',
                templateUrl: '/Scripts/app/views/audit/revenue/checked.html',
                controller: 'DailyCollectionCtrl'
            });
            $stateProvider.state('/Report/Revenue/By/Rate', {
                url: '/Report/Revenue/By/Rate',
                templateUrl: '/Scripts/app/views/report/revenue-by-rate/index.html',
                controller: 'RevenuePivotCtrl'
            });
            $stateProvider.state('/Report/Adjustment/Summary', {
                url: '/Report/Adjustment/Summary',
                templateUrl: '/Scripts/app/views/report/adjustment-pivot/index.html',
                controller: 'AdjustmentPivotCtrl'
            });
            $stateProvider.state('/Messages', {
                url: '/Messages',
                templateUrl: '/Scripts/app/views/messages/index.html',
                controller: 'MessagesCtrl'
            })
            ;
            $stateProvider.state('/Report/CashBalance', {
                url: '/Report/CashBalance',
                templateUrl: '/Scripts/app/views/report/cash-balance/index.html',
                controller: 'CashBalanceReportCtrl'
            });
            $stateProvider.state('/Report/Audit', {
                url: '/Report/Audit',
                templateUrl: '/Scripts/app/views/report/audit/index.html',
                controller: 'AuditReportCtrl'
            });
            $stateProvider.state('/Incidents', {
                url: '/Incidents',
                templateUrl: '/Scripts/app/views/incident/index.html',
                controller: 'IncidentManagementCtrl'
            });
            $stateProvider.state('/Incidents/Add', {
                url: '/Incidents/Add',
                templateUrl: '/Scripts/app/views/incident/add.html',
                controller: 'IncidentCreateEditCtrl',
                resolve: {
                    'locations': ['locationSvc', function (locationSvc) {
                        return locationSvc.getLocations();
                    }]
                }
            });
            $stateProvider.state('/Incidents/Edit', {
                url: '/Incidents/Edit/:id',
                templateUrl: '/Scripts/app/views/incident/add.html',
                controller: 'IncidentCreateEditCtrl',
                resolve: {
                    'locations': ['locationSvc', function (locationSvc) {
                        return locationSvc.getLocations();
                    }]
                }
            });
            $stateProvider.state('/Admin', {
                url: '/Admin',
                templateUrl: '/Scripts/app/views/admin/index.html',
                controller: 'HomeCtrl'
            });
            $stateProvider.state('/Admin/Roles', {
                url: '/Admin/Roles',
                templateUrl: '/Scripts/app/views/admin/roles/index.html',
                controller: 'RolesCtrl'
            });
            $stateProvider.state('/Admin/Roles/Add', {
                url: '/Admin/Roles/Add',
                templateUrl: '/Scripts/app/views/admin/roles/add.html',
                controller: 'RoleCtrl'
            });
            $stateProvider.state('/Admin/Roles/Edit', {
                url: '/Admin/Roles/Edit/:id',
                templateUrl: '/Scripts/app/views/admin/roles/edit.html',
                controller: 'RoleCtrl'
            });
            $stateProvider.state('/Admin/Users', {
                url: '/Admin/Users',
                templateUrl: '/Scripts/app/views/admin/users/index.html',
                controller: 'UsersCtrl'
            });
            $stateProvider.state('/Admin/Users/Register', {
                url: '/Admin/Users/Register',
                templateUrl: '/Scripts/app/views/admin/users/create.html',
                controller: 'UserCtrl'
            });
            $stateProvider.state('/Admin/Users/Edit', {
                url: '/Admin/Users/Edit/:id',
                templateUrl: '/Scripts/app/views/admin/users/edit.html',
                controller: 'UserCtrl'
            });
            $stateProvider.state('/Admin/Users/Days', {
                url: '/Admin/Users/Days/:id',
                templateUrl: '/Scripts/app/views/admin/users/days.html',
                controller: 'UserDayCtrl'
            });

            $stateProvider.state('/Admin/LocationTypes', {
                url: '/Admin/LocationTypes',
                templateUrl: '/Scripts/app/views/admin/location_types/index.html',
                controller: 'LocationTypesCtrl'
            });
            $stateProvider.state('/Admin/LocationTypes/Add', {
                url: '/Admin/LocationTypes/Add',
                templateUrl: '/Scripts/app/views/admin/location_types/add.html',
                controller: 'LocationTypeCtrl'
            });
            $stateProvider.state('/Admin/LocationTypes/Edit', {
                url: '/Admin/LocationTypes/Edit/:id',
                templateUrl: '/Scripts/app/views/admin/location_types/edit.html',
                controller: 'LocationTypeCtrl'
            });
            $stateProvider.state('/Admin/Services', {
                url: '/Admin/Services',
                templateUrl: '/Scripts/app/views/admin/service/index.html',
                controller: 'ServicesCtrl'
            });
            $stateProvider.state('/Admin/Services/Add', {
                url: '/Admin/Services/Add',
                templateUrl: '/Scripts/app/views/admin/service/add.html',
                controller: 'ServiceCtrl'
            });
            $stateProvider.state('/Admin/Services/Edit', {
                url: '/Admin/Services/Edit/:id',
                templateUrl: '/Scripts/app/views/admin/service/edit.html',
                controller: 'ServiceCtrl'
            });
            $stateProvider.state('/Admin/TicketTypes', {
                url: '/Admin/TicketTypes',
                templateUrl: '/Scripts/app/views/admin/ticket_type/index.html',
                controller: 'TicketTypesCtrl'
            });
            $stateProvider.state('/Admin/TicketTypes/Add', {
                url: '/Admin/TicketTypes/Add',
                templateUrl: '/Scripts/app/views/admin/ticket_type/add.html',
                controller: 'TicketTypeCtrl'
            });
            $stateProvider.state('/Admin/TicketTypes/Edit', {
                url: '/Admin/TicketTypes/Edit/:id',
                templateUrl: '/Scripts/app/views/admin/ticket_type/edit.html',
                controller: 'TicketTypeCtrl'
            });
            $stateProvider.state('/Admin/PaymentTypes', {
                url: '/Admin/PaymentTypes',
                templateUrl: '/Scripts/app/views/admin/payment_type/index.html',
                controller: 'PaymentTypesCtrl'
            });
            $stateProvider.state('/Admin/PaymentTypes/Add', {
                url: '/Admin/PaymentTypes/Add',
                templateUrl: '/Scripts/app/views/admin/payment_type/add.html',
                controller: 'PaymentTypeCtrl'
            });
            $stateProvider.state('/Admin/PaymentTypes/Edit', {
                url: '/Admin/PaymentTypes/Edit/:id',
                templateUrl: '/Scripts/app/views/admin/payment_type/edit.html',
                controller: 'PaymentTypeCtrl'
            });
            $stateProvider.state('/Admin/IncidentTypes', {
                url: '/Admin/IncidentTypes',
                templateUrl: '/Scripts/app/views/admin/incident_type/index.html',
                controller: 'IncidentTypesCtrl'
            });
            $stateProvider.state('/Admin/IncidentTypes/Add', {
                url: '/Admin/IncidentTypes/Add',
                templateUrl: '/Scripts/app/views/admin/incident_type/add.html',
                controller: 'IncidentTypeCtrl'
            });
            $stateProvider.state('/Admin/IncidentTypes/Edit', {
                url: '/Admin/IncidentTypes/Edit/:id',
                templateUrl: '/Scripts/app/views/admin/incident_type/edit.html',
                controller: 'IncidentTypeCtrl'
            });
            $stateProvider.state('/Admin/AdjustmentTypes', {
                url: '/Admin/AdjustmentTypes',
                templateUrl: '/Scripts/app/views/admin/adjustment_type/index.html',
                controller: 'AdjustmentTypesCtrl'
            });
            $stateProvider.state('/Admin/AdjustmentTypes/Add', {
                url: '/Admin/AdjustmentTypes/Add',
                templateUrl: '/Scripts/app/views/admin/adjustment_type/add.html',
                controller: 'AdjustmentTypeCtrl'
            });
            $stateProvider.state('/Admin/AdjustmentTypes/Edit', {
                url: '/Admin/AdjustmentTypes/Edit/:id',
                templateUrl: '/Scripts/app/views/admin/adjustment_type/edit.html',
                controller: 'AdjustmentTypeCtrl'
            });
            $stateProvider.state('/Admin/ExpenseTypes', {
                url: '/Admin/ExpenseTypes',
                templateUrl: '/Scripts/app/views/admin/expense_type/index.html',
                controller: 'ExpenseTypesCtrl'
            });
            $stateProvider.state('/Admin/ExpenseTypes/Add', {
                url: '/Admin/ExpenseTypes/Add',
                templateUrl: '/Scripts/app/views/admin/expense_type/add.html',
                controller: 'ExpenseTypeCtrl'
            });
            $stateProvider.state('/Admin/ExpenseTypes/Edit', {
                url: '/Admin/ExpenseTypes/Edit/:id',
                templateUrl: '/Scripts/app/views/admin/expense_type/edit.html',
                controller: 'ExpenseTypeCtrl'
            });
            $stateProvider.state('/Admin/Shifts', {
                url: '/Admin/Shifts',
                templateUrl: '/Scripts/app/views/admin/shift/index.html',
                controller: 'ShiftsCtrl'
            });
            $stateProvider.state('/Admin/Shifts/Add', {
                url: '/Admin/Shifts/Add',
                templateUrl: '/Scripts/app/views/admin/shift/add.html',
                controller: 'ShiftCtrl'
            });
            $stateProvider.state('/Admin/Shifts/Edit', {
                url: '/Admin/Shifts/Edit/:id',
                templateUrl: '/Scripts/app/views/admin/shift/edit.html',
                controller: 'ShiftCtrl'
            });
            $stateProvider.state('/Admin/Settings', {
                url: '/Admin/Settings',
                templateUrl: '/Scripts/app/views/admin/configuration/index.html',
                controller: 'ConfigurationCtrl'
            });
            $stateProvider.state('/Schedules', {
                url: '/Schedules',
                templateUrl: '/Scripts/app/views/schedule/create.html',
                controller: 'ScheduleCreateCtrl'
            });
            $stateProvider.state('/MySchedules', {
                url: '/MySchedules',
                templateUrl: '/Scripts/app/views/schedule/employee/my-schedule.html',
                controller: 'MyScheduleCtrl'
            });
            $stateProvider.state('/Schedules/Create', {
                url: '/Schedules/Create',
                templateUrl: '/Scripts/app/views/schedule/create.html',
                controller: 'ScheduleCreateCtrl'
            });
            $stateProvider.state('/Schedules/View', {
                url: '/Schedules/View',
                templateUrl: '/Scripts/app/views/schedule/view.html',
                controller: 'ScheduleCreateCtrl'
            });
            $stateProvider.state('/Schedules/EmployeePreferences', {
                url: '/Schedules/EmployeePreferences',
                templateUrl: '/Scripts/app/views/schedule/employee-preferences/index.html',
                controller: 'EmployeePreferenceCtrl'
            });
            $stateProvider.state('/Schedules/EmployeePreferences/Edit/:id', {
                url: '/Schedules/EmployeePreferences/Edit/:id',
                templateUrl: '/Scripts/app/views/schedule/employee-preferences/edit.html',
                controller: 'EmployeePreferenceEditCtrl'
            });
            $stateProvider.state('/Reports', {
                url: '/Reports',
                templateUrl: '/Scripts/app/views/report/index.html',
                controller: 'ReportCtrl'
            });
            $stateProvider.state('/Reports/Employee', {
                url: '/Reports/Employee',
                templateUrl: '/Scripts/app/views/report/employee/index.html',
                controller: 'EmployeeReportCtrl'
            });
            $stateProvider.state('/Reports/Location', {
                url: '/Reports/Location',
                templateUrl: '/Scripts/app/views/report/location/index.html',
                controller: 'LocationReportCtrl'
            });

            $stateProvider.state('/Reports/Ticket', {
                url: '/Reports/Ticket',
                templateUrl: '/Scripts/app/views/report/tickets/index.html',
                controller: 'TicketReportCtrl'
            });
            $stateProvider.state('/Reports/Location/ById', {
                url: '/Reports/Location/:id',
                templateUrl: '/Scripts/app/views/report/location/detail.html',
                controller: 'LocationReportCtrl'
            });
            $stateProvider.state('/Reports/Daily', {
                url: '/Reports/Daily',
                templateUrl: '/Scripts/app/views/report/daily/index.html'
            });
            $stateProvider.state('/Reports/Revenue', {
                url: '/Reports/Revenue',
                templateUrl: '/Scripts/app/views/report/location/index.html'
            });

            $urlRouterProvider.otherwise('/Home');
            //$stateProvider.otherwise({redirectTo: '/Login'});
            $httpProvider.interceptors.push('authorizationInterceptor');
        }]);

app.scrollTop = function () {
    $('body,html').animate({
        scrollTop: 0
    }, 600);
};

app.filter('propsFilter', function () {
    return function (items, props) {
        var out = [];

        if (angular.isArray(items)) {
            items.forEach(function (item) {
                var itemMatches = false;

                var keys = Object.keys(props);
                for (var i = 0; i < keys.length; i++) {
                    var prop = keys[i];
                    var text = props[prop].toLowerCase();
                    if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                        itemMatches = true;
                        break;
                    }
                }

                if (itemMatches) {
                    out.push(item);
                }
            });
        } else {
            // Let the output be the input untouched
            out = items;
        }
        return out;
    };
});

app.hasRole = function (role) {
    if (app.roles !== undefined && angular.isArray(app.roles)) {
        return (app.roles.indexOf(role) >= 0);
    }
    return false;
};

window.utilities = angular.module("custom-utilities", []);
