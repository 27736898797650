angular
    .module('ustreet-app')
    .controller('CashBalanceReportCtrl', ['$scope', '$window', 'userSvc', 'serviceHelperSvc', '$filter', function ($scope, $window, userSvc, serviceHelperSvc, $filter) {
        $scope.hasRole = app.hasRole;

        $scope.toMonth = function (monthNumber) { //1 = January
            var monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'];
            return monthNames[monthNumber - 1];
        };

        $scope.ParamChanged = function () {
            $scope.loading = true;
            $scope.data = serviceHelperSvc.CashReconciliationSummary.get({startDate: $filter('date')($scope.filter.startDate, 'MM-dd-yyyy')});

            $scope.data.$promise.then(function (data) {
                var subtotal = 0;
                for (var i = 0; i < $scope.data.length; i++) {
                    if ($scope.data[i].TransactionType.indexOf('Income') >= 0) {
                        subtotal = $scope.data[i].Amount + subtotal;
                    } else {
                        subtotal = subtotal - $scope.data[i].Amount;
                    }
                    $scope.data[i].Balance = subtotal;
                }
                $scope.loading = false;
            });
        };

    }]);
