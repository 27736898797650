angular
    .module('ustreet-app')
    .factory('mainSvc',
    ['$http', 'serviceHelperSvc', '$cookies', 'localStorageService',
        function ($http, serviceHelper, $cookies, localStorageService) {

            var Token = serviceHelper.AuthorizationToken;
            var Account = serviceHelper.Account;

            if (angular.isUndefined($http.defaults.headers.common.Authorization) && !angular.isUndefined(localStorageService.get('token'))) {
                $http.defaults.headers.common.Authorization = localStorageService.get('token');
                app.authenticated = true;
            }

            var buildFormData = function (formData) {
                var dataString = '';
                for (var prop in formData) {
                    if (formData.hasOwnProperty(prop)) {
                        dataString += (prop + '=' + formData[prop] + '&');
                    }
                }
                return dataString.slice(0, dataString.length - 1);
            };

            return {
                login: function (userLogin) {
                    var formData = {
                        username: userLogin.Email,
                        password: userLogin.Password,
                        grant_type: 'password'
                    };
                    return Token.requestToken(buildFormData(formData),
                        function (data) {
                            $http.defaults.headers.common.Authorization = "Bearer " + data.token;
                            localStorageService.set('token',
                                $http.defaults.headers.common.Authorization);
                            app.username = data.userName;
                            localStorageService.set('UserName', app.username);

                            app.authenticated = true;

                            if (data.ChangePassword === "true") {
                                app.change_password = true;
                            }
                        });
                },
                registerUser: function (userRegistration) {
                    var registration = Account.register(userRegistration);

                    return registration;
                },
                logOffUser: function () {
                    $http.defaults.headers.common.Authorization = null;
                    app.authenticated = false;
                    app.roles = [];
                    app.username = null;
                    localStorageService.clearAll();
                    $cookies.remove('token');
                },
                isAuthed: function () {
                    return ((!angular.isUndefined($http.defaults.headers.common.Authorization) || !angular.isUndefined($cookies.get('token'))));
                }
            };
        }]);
