angular
    .module('ustreet-app')
    .factory('dailyReportSvc',
        ['$resource', 'serviceHelperSvc', function ($resource, serviceHelper) {
            const Service = serviceHelper.Daily;

            const add = function (item) {
                //$resource.save will immediately return an object which will have $promise property.
                //This property will get resolved with values, once the Server returns response
                return Service.save(item);
            };

            const edit = function (item) {
                const updation = serviceHelper.DailySubmit.put(item).$promise;
                return updation;
            };

            const removeTicket = function (params) {
                return serviceHelper.DailyTicketRemove.remove(params);
            };

            const post = function (item) {
                const updation = Service.post(item).$promise;
                return updation;
            };

            const getItem = function (id) {
                return serviceHelper.DailyReport.get({id: id}).$promise;
            };

            const create = function (period, locationId) {
                return serviceHelper.DailyCreate.get({
                    date: period.Date,
                    locationId: locationId,
                    shiftId: period.ShiftId
                }).$promise;
            };

            const getListByLocation = function (locationId) {
                return serviceHelper.DailyReportList.get({id: locationId});
            };

            const getList = function () {
                return Service.query();
            };
            const deleteItem = function (id) {
                return serviceHelper.DailyReportDelete.delete({Id: id});
            };

            const open = function (id) {
                return serviceHelper.CustomDailyReport.get({locationId: id});
            };

            const uploadDepositSlip = function (id, upload) {
                return serviceHelper.UploadDepositSlip.update(upload);
            };

            return {
                add: add,
                edit: edit,
                post: post,
                getItem: getItem,
                getList: getList,
                getListByLocation: getListByLocation,
                deleteItem: deleteItem,
                create: create,
                removeTicket: removeTicket,
                open: open,
                uploadDepositSlip: uploadDepositSlip
            };
        }]);
