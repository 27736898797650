angular
    .module('ustreet-app')
    .controller('IncidentCreateEditCtrl', [
        '$q', '$scope', '$stateParams', '$filter', '$location', 'incidentSvc', 'locations', 'incidentTypeSvc', 'userSvc', function ($q, $scope, $stateParams, $filter, $location, service, locations, incidentTypeSvc, userSvc) {
            let url = '/Incidents';

            $scope.OnImageChanged = function (file) {
                let reader = new FileReader();
                reader.onload = function (e) {
                    $scope.NewImage = e.target.result;
                    $scope.item.Images = ($scope.item.Images == undefined) ? [] : $scope.item.Images;
                    $scope.item.Images.push({Image: $scope.NewImage});
                    $scope.$apply();
                };
                reader.readAsDataURL(file.target.files[0]);

            };

            $scope.employees = userSvc.getList();
            $scope.locations = locations;
            $scope.types = incidentTypeSvc.getList();

            $scope.save = function (item) {
                $scope.saving = true;
                //item.EventDate = new Date(item.EventDate);
                if (item.ID !== undefined) {
                    return $scope.edit(item);
                }
                service.add(item).$promise
                    .then(function (data) {
                        $location.url(url);
                    }, function (data) {
                        // this is an error
                        $scope.message = data.message;
                        $scope.saving = false;
                    });

            };

            $scope.hasRole = app.hasRole;

            $scope.close = function (item) {
                item.Status = 'CLOSED';
                $scope.save(item);
            };

            $scope.edit = function (item) {
                service.edit($scope.item).$promise.then(function (data) {
                    $location.url(url);
                });
            };

            $scope.showImage = function (img) {
                $scope.CurrentImage = img;
                $scope.show_modal = true;
            };

            $scope.closeModal = function () {
                $scope.show_modal = false;
            };

            function loadWithImage() {
                var deferred = $q.defer();
                setTimeout(function () {
                    $scope.itemWithImages = service.getWithImages($stateParams.id);
                    $scope.itemWithImages.$promise.then(function () {
                        $scope.imagesLoaded = true;
                        deferred.resolve($scope.itemWithImages);
                    });
                }, 1000);
                return deferred.$promise;
            }

            function init() {
                $scope.isReady = false;
                locations.$promise.then(function () {
                    if ($stateParams.id !== 0 && $stateParams.id !== undefined) {
                        $scope.imagesLoaded = false;
                        $scope.item = service.getItem($stateParams.id);
                        $scope.item.$promise.then(function () {
                            console.log($scope.item);
                            $scope.isReady = true;
                            $scope.item.EventDate = $filter('date')(new Date($scope.item.EventDate), 'yyyy-MM-dd');
                            loadWithImage();
                        });
                    } else {
                        $scope.isReady = true;
                    }
                });


            }

            init();
        }
    ]);
