angular
    .module('ustreet-app')
    .controller('RevenuePivotCtrl', ['$scope', '$window', '$location', '$stateParams', '$filter', 'serviceHelperSvc', function ($scope, $window, $location, $stateParams, $filter, serviceHelperSvc) {

        $scope.locations = serviceHelperSvc.Location.query();
        $scope.filter = {
            startDate: $location.search().startDate,
            lastDate: $location.search().endDate,
            location: $location.search().location
        };

        function pivotMyData(data) {
            $scope.prices = _.uniq(_.pluck(data, 'Rate')).sort((a, b) => a - b);
            $scope.paymentTypes = _.uniq(_.pluck(data, 'PaymentType'));
            $scope.dates = _.uniq(_.pluck(data, 'ReportDate'));
            $scope.headers = $scope.paymentTypes.flatMap(p => $scope.prices.map(r => {
                return {Name: p + ' ' + r, PaymentType: p, Rate: r};
            }));
            console.log($scope.headers);
            $scope.pivot = [];
            $scope.dates.forEach((date) => {
                if ($scope.pivot.indexOf(date) === -1) {
                    $scope.pivot[date] = {ReportDate: date, data: []};
                }
                let reportsForDate = _.where(data, {ReportDate: date});
                if (reportsForDate.length > 0) {
                    $scope.pivot[date].SupervisorName = reportsForDate[0].SupervisorName;
                }

                for (let pt in $scope.paymentTypes) {
                    $scope.pivot[date][$scope.paymentTypes[pt]] = [];
                    let pts = _.where(reportsForDate, {PaymentType: $scope.paymentTypes[pt]});
                    for (let rt in $scope.prices) {
                        $scope.pivot[date][$scope.paymentTypes[pt]][$scope.prices[rt]] = _.findWhere(pts, {Rate: $scope.prices[rt]});
                    }
                }
            });
        }

        $scope.ChangeParams = function () {
            $scope.prices = $scope.rows = $scope.pivot = null;
            $scope.loading = true;
            if ($scope.filter.startDate !== undefined && $scope.filter.lastDate !== undefined && $scope.filter.location !== undefined) {
                $location.search('startDate', $filter('date')($scope.filter.startDate, 'MM-dd-yyyy'));
                $location.search('endDate', $filter('date')($scope.filter.lastDate, 'MM-dd-yyyy'));
                $location.search('location', $scope.filter.location);

                let filter = {
                    startDate: $filter('date')($scope.filter.startDate, 'MM-dd-yyyy'),
                    endDate: $filter('date')($scope.filter.lastDate, 'MM-dd-yyyy'),
                    locationId: $scope.filter.location
                };
                $scope.rows = serviceHelperSvc.RevenuePivot.query(filter);
                $scope.rows.$promise.then(function (data) {
                    pivotMyData(data);
                    $scope.loading = false;
                }).catch((response) => {
                    $scope.loading = false;
                    $scope.hasPermission = false;
                });
            }
        };

        $scope.ExportExcel = function () {
            if ($scope.filter.startDate !== undefined && $scope.filter.lastDate !== undefined && $scope.filter.location !== undefined) {
                let filter = {
                    startDate: $filter('date')($scope.filter.startDate, 'MM-dd-yyyy'),
                    endDate: $filter('date')($scope.filter.lastDate, 'MM-dd-yyyy'),
                    locationId: $scope.filter.location
                };
                let param = jQuery.param(filter);
                let url = config.apiurl + 'api/PivotRevenue/Excel?' + param;
                $window.open(url, "_BLANK");
            }
        };
    }]);
