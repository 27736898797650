angular
    .module('ustreet-app')
    .controller('MyScheduleCtrl', ['$scope', 'scheduleService', '$stateParams', '$filter', function ($scope, scheduleService, $stateParams, $filter) {

    var schedule = scheduleService.MySchedule.get();
    schedule.$promise.then(function (data) {
        $scope.rows = data.rows;
        $scope.prows = data.prows;
    });

}]);
