angular
    .module('ustreet-app')
    .controller(
    'PaymentCategoriesCtrl', ['$scope', 'paymentCategorySvc',
        function ($scope, paymentCategorySvc) {
            $scope.list = [];

            this.init = function () {
                $scope.list = paymentCategorySvc.getList();
            };

            this.init();
        }
    ]
);