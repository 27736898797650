angular
    .module('ustreet-app')
    .controller(
    'ServicesCtrl', ['$scope', 'serviceTypeSvc',
        function ($scope, serviceTypeSvc) {
            $scope.list = [];

            this.init = function () {
                $scope.list = serviceTypeSvc.getList();
            };

            this.init();
        }
    ]
);