angular
    .module('ustreet-app')
    .controller('DailyReportCtrl', ['$scope', 'ticketTypeSvc', 'userSvc', 'paymentTypeSvc', 'dailyReportSvc', 'locations', '$location', 'currentUserService', '$stateParams',
    function($scope, ticketTypeSvc, userSvc, paymentTypeSvc, dailyReportSvc, locations, $location, currentUserService, $stateParams) {

        $scope.OnLocationChanged = function () {
            $scope.loading = true;
            $scope.options = dailyReportSvc.getListByLocation($scope.LocationId);
            $scope.options.$promise.then(function(d) {
                $scope.loading = false;
                $scope.MarkReady();
            });
        };


        $scope.Create = function(period) {
          $scope.processing = true;
          $scope.loading = true;
            dailyReportSvc.create(period, $scope.LocationId).then(function(data) {
                $location.path('/Daily/Wizard/' + data.Id);
            });
        };

        $scope.Continue = function(period) {
            $location.path('Daily/Wizard/' + period.Id);
        };

        $scope.OpenCustomDate = function() {
            $scope.creatingCustomReport = true;
            $scope.loading = true;
            const open = dailyReportSvc.open($scope.LocationId);
            open.$promise.then(function() {
                $scope.creatingCustomReport = false;
                $scope.OnLocationChanged();
            });
        };

        $scope.init = function(){
          $scope.ready = false;
          $scope.locations = locations;
          $scope.locations.$promise.then(function(){
            if ($stateParams.id !== undefined) {
                $scope.LocationId = $stateParams.id;
                $scope.OnLocationChanged();
            } else {
                // this gets the default list
                $scope.options = dailyReportSvc.getList();
                $scope.options.$promise.then(function(d){
                   $scope.LocationId = d[0].LocationId;
                   $scope.MarkReady();
                });
            }
          });

        };

        $scope.MarkReady = function(){
          $scope.ready =true;
        };

        $scope.init();
    }
]);
