angular
    .module('ustreet-app')
    .controller('UserDayCtrl', ['$scope', '$location', '$stateParams', 'userSvc', 'serviceHelperSvc',
    function ($scope, $location, $stateParams, userSvc, serviceHelper) {

        function init() {
            if ($stateParams.id) {
                $scope.user = userSvc.getItem($stateParams.id);
                $scope.days = serviceHelper.UserDay.get({ id: $stateParams.id });
                $scope.days.$promise.then(function (data) {
                    angular.forEach($scope.days, function (d, i) {
                        d.StartTime = Date.toLocalDate(d.StartTime);
                    });
                });
            }
        }

        init();

        $scope.editDays = function () {
            serviceHelper.UserDay.put($scope.user.Id, $scope.days)
             .$promise
            .then(function (data) {
                //console.info(data);
                $location.path('/Admin/Users');
            }, function (error) {
                //console.info('Your changes have not been saved. ');
            });
        };

    }]);