angular
    .module('ustreet-app')
    .controller(
    'MessagesCtrl', ['$scope', 'userSvc', 'serviceHelperSvc','roleSvc',
        function ($scope, userSvc, serviceHelperSvc, roleSvc) {
            $scope.list = [];
            $scope.notfy = undefined;
            $scope.confirmation = undefined;
            $scope.roles = roleSvc.getList();

            $scope.ChangeParams = function(){
                $scope.list = userSvc.getByRole($scope.role);
            };

            $scope.toggleSelected = function () {
                for (var i = 0; i < $scope.list.length; i++) {
                    $scope.list[i].IsSelected = $scope.toggle;
                }
            };

            $scope.sendMessage = function () {
                var users = [];

                for (var i = 0; i < $scope.list.length; i++) {
                    if ($scope.list[i].IsSelected) {
                        users.push($scope.list[i].PhoneNumber);
                    }
                }

                if (users.length === 0) {
                    $scope.notify = ("Please select a few users before you click send");
                    return;
                }

                var message = {
                    Message: $scope.message.Text,
                    Numbers: users
                };

                var onSuccess = function (data) {
                  if(angular.isDefined(data.Numbers)){
                      $scope.confirmation = "Message sent for " + data.Numbers.length + ' Number(s)';
                  }else{
                      $scope.notify = "Error sending - Contact admin";
                  }

                };

                var onFailure = function () {
                    $scope.notify = "Error sending";
                };

                var promise = serviceHelperSvc.Message.send(message).$promise;
                promise.then(onSuccess, onFailure);
            };


        }
    ]
);
