angular
    .module('ustreet-app')
    .controller('MainCtrl',
    ['$scope', '$http', '$location', 'mainSvc', 'roleSvc', 'serviceHelperSvc',
        'localStorageService', '$window', '$cookies',
        function ($scope, $http, $location, mainSvc, roleSvc, serviceHelper,
            localStorageService, $window, $cookies) {

            $scope.isAuthorized = function () {
                var roles = localStorageService.get('Roles');
                return roles !== undefined && roles !== null && roles.length > 0;
            };

            $scope.authorized = $scope.isAuthorized();

            $scope.login = function (userLogin) {
                $scope.loading = true;
                $scope.errorMessage = '';
                mainSvc.login(userLogin).$promise
                .then(function (data) {
                    $scope.checkStatus();
                }).catch(function (errorResponse) {
                    if (errorResponse.status === 404) {
                        $scope.errorMessage = errorResponse.data[0].Message;
                    }
                    if (errorResponse.status === 400) {
                        $scope.errorMessage = "Invalid Login/Password";
                    } else {
                        $scope.errorMessager = "An error occurred while performing this action. Please try after some time.";
                    }
                    $scope.loading = false;
                });

            };

            $scope.checkStatus = function () {
                serviceHelper.UserRole.query(function (data) {
                    var roles = [];
                    angular.forEach(data, function (role) {
                        roles.push(role.Key);
                    });

                    localStorageService.set('Roles', roles);
                    localStorageService.set('UserName', app.username);
                    app.roles = roles;

                    $scope.$emit('logOn');
                    $window.location.href = '/#/Home';
                });
            };

            $scope.logOff = function () {
                app.authenticated = false;
                app.roles = [];
                app.username = null;
                serviceHelper.Logout.logOut();
                localStorageService.clearAll();
                $cookies.remove('token');

                $scope.$emit('logOff');
                // $location.url('/Login');
                $window.location.href = '/login.html';
            };
        }]);

angular
    .module('ustreet-app')
    .controller('LoginCtrl', ['$scope', 'localStorageService', '$cookies',
    function ($scope, localStorageService, $cookies) {
        $scope.$emit('logOffUser');
        //$http.defaults.headers.common.Authorization = null;
        app.authenticated = false;
        app.roles = [];
        app.username = null;
        localStorageService.clearAll();
        $cookies.remove('token');
    }]);
