angular
    .module('ustreet-app')
    .controller('AdjustmentPivotCtrl', ['$scope', '$location', '$window', '$stateParams', '$filter', 'serviceHelperSvc', function ($scope, $location, $window, $stateParams, $filter, serviceHelperSvc) {

    $scope.locations = serviceHelperSvc.Location.query();
    $scope.filter = {lastDate : new Date()};

    function pivotMyData(data){
        $scope.prices = _.uniq(_.pluck(data, 'Rate'));
        $scope.paymentTypes = _.uniq(_.pluck(data, 'PaymentType'));
        $scope.dates = _.uniq( _.pluck(data, 'ReportDate'));

        $scope.pivot = [];
        for( var date in $scope.dates){
            date = $scope.dates[date];
            if($scope.pivot.indexOf(date) === -1){
                $scope.pivot[date] = {ReportDate : date, data: []};
            }
            var reportsForDate = _.where(data, {ReportDate: date});
            if(reportsForDate.length > 0){
                $scope.pivot[date].Supervisor = reportsForDate[0].SupervisorName;
            }

            for(var pt in $scope.paymentTypes){
                $scope.pivot[date][$scope.paymentTypes[pt]] = [];
                var pts = _.where(reportsForDate, {PaymentType : $scope.paymentTypes[pt]});
                for(var rt in $scope.prices){
                    $scope.pivot[date][$scope.paymentTypes[pt]][$scope.prices[rt]] = _.findWhere(pts, {Rate: $scope.prices[rt]});
                }
            }
        }
       $scope.loading = false;
    }

    $scope.ChangeParams = function () {
        console.log($scope.filter);
        $scope.prices = $scope.rows = $scope.pivot = null;
        $scope.loading = true;
        if ($scope.filter.startDate !== undefined && $scope.filter.lastDate !== undefined && $scope.location !== undefined) {
            var filter = { startDate: $filter('date')($scope.filter.startDate, 'MM-dd-yyyy'), endDate: $filter('date')($scope.filter.lastDate, 'MM-dd-yyyy'), locationId: $scope.location };
            $scope.rows = serviceHelperSvc.AdjustmentPivot.query(filter);
            $scope.rows.$promise.then(function (data) {
                pivotMyData(data);
            });
        }
    };

    $scope.ExportExcel = function(){
      if ($scope.filter.startDate !== undefined && $scope.filter.lastDate !== undefined && $scope.location !== undefined) {
          var filter = { startDate: $filter('date')($scope.filter.startDate, 'MM-dd-yyyy'), endDate: $filter('date')($scope.filter.lastDate, 'MM-dd-yyyy'), locationId: $scope.location };
          var param = jQuery.param( filter );
          var url = config.apiurl + 'api/PivotAdjustments/Excel?' + param;
          $window.open(url,"_BLANK");
      }
    };
}]);
