angular
    .module('ustreet-app')
    .controller('TicketInventoryCtrl', ['$scope', '$location', '$stateParams', 'ticketInventorySvc', 'ticketTypeSvc', 'userSvc', 'locationSvc',
        function ($scope, $location, $stateParams, svc, ticketTypeSvc, userSvc, locationSvc) {

            $scope.ticketTypes = ticketTypeSvc.getActiveList();


            $scope.add = function (item) {
                if (parseInt(item.EndNumber) > parseInt(item.StartNumber)) {


                    var prm;

                    if ($scope.isEdit) {
                        prm = svc.updateReceiveDetail(item).$promise;
                    } else {
                        prm = svc.add(item).$promise;
                    }
                    prm
                        .then(function () {
                            $location.url('/Inventory');
                        }, function (response) {
                            $scope.Warning = response.data.message;
                        });
                } else {
                    $scope.Warning = "End number cannot be less than start number.";
                }

            };

            if ($stateParams.id !== undefined) {
                $scope.isEdit = true;
                $scope.users = userSvc.getList();
                $scope.receiveDetail = svc.getReceiveDetail($stateParams.id);

                $scope.locations = locationSvc.getActiveLocations();

                $scope.receiveDetail.$promise.then(function () {
                    $scope.item = $scope.receiveDetail;
                    console.log($scope.receiveDetail);
                });
            }

        }]);
