function PaymentsCtrl($scope, paymentSvc, paymentCategorySvc, projectSvc, userSvc) {

    $scope.page = 0;
    $scope.showConfirmedPayments = false;
    $scope.list = [];

    $scope.projects = projectSvc.getList();
    $scope.paymentCategories = paymentCategorySvc.getList();

    $scope.init = function (p) {
        if (p !== undefined) {
            $scope.page = p;
        }
        $scope.loading = true;
        $scope.hasPermission = true;
        $scope.request = paymentSvc.getList({includeConfirmed: $scope.showConfirmedPayments, pageNumber: $scope.page});

        $scope.request.$promise.then(function (d) {
            $scope.list = d.List;
            $scope.total_payments = d.TotalCount;
            $scope.pages = [];
            for (let i = 0; i <= d.TotalCount / 50; i++) {
                $scope.pages.push(i);
            }
            $scope.loading = false;
        }).catch(function (e) {
            $scope.loading = false;
            $scope.hasPermission = false;
        });
    };

    $scope.OpenModalForDeleteDraftPayment = function (id) {
        $scope.show_are_you_sure = true;
        $scope.id_to_delete = id;
    };

    $scope.CancelDialog = function () {
        $scope.show_are_you_sure = false;
    };

    $scope.DeleteDraftPayment = function () {
        $scope.delete_error_message = '';
        paymentSvc.deleteItem($scope.id_to_delete)

            .$promise
            .then(function (data) {
                    $scope.messsage = 'Deleted the draft payment.';
                    $scope.init($scope.page);
                    $scope.show_are_you_sure = false;
                }
            ).catch(function (data) {
            $scope.delete_error_message = 'This draft cannot be deleted due to error.';
        });
    };

    $scope.init();
    // initialize the checkboxes
    $('.ui.checkbox')
        .checkbox()
    ;
}

angular
    .module('ustreet-app')
    .component('paymentList', {
        bindings: 'paymentList',
        controller: PaymentsCtrl,
        templateUrl: '/Scripts/app/components/payment/partials/index.html'
    });

PaymentsCtrl.$inject = ['$scope', 'paymentSvc', 'paymentCategorySvc', 'projectSvc', 'userSvc']
