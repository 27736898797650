angular
    .module('ustreet-app')
    .controller(
        'ProjectsCtrl', ['$scope', 'projectSvc',
            function ($scope, paymentCategorySvc) {
                $scope.list = [];

                $scope.ApplyFilter = function () {
                    if ($scope.ShowAll) {
                        $scope.list = $scope.fullData;
                    } else {
                        $scope.list = $scope.fullData.filter(function (r) {
                            return r.IsActive === true;
                        });
                    }

                };
                this.init = function () {
                    $scope.fullData = paymentCategorySvc.getList();
                    $scope.fullData.$promise.then(function () {
                        $scope.ApplyFilter();
                    });

                };

                this.init();
                $scope.ShowAll = false;

            }
        ]
    );