angular
    .module('ustreet-app')
    .factory('paymentSvc', ['$resource', 'serviceHelperSvc', function ($resource, serviceHelper) {
    var Service = serviceHelper.Payment;
    var add = function (item) {
        //$resource.save will immediately return an object which will have $promise property.
        //This property will get resolved with values, once the Server returns response
        return Service.save(item);
    };
    var edit = function (item) {
        return Service.update(item);
    };
    var getItem = function (id) {
        return Service.get({ id: id });
    };
    var getList = function (param) {
        return Service.query(param);
    };
    var deleteItem = function (id) {
        return Service.delete({ id: id });
    };

    var InitiateNewPayroll = function(){
        return serviceHelper.InitiateNewPayroll.get();
    };


    return {
        add: add,
        edit: edit,
        getItem: getItem,
        getList: getList,
        InitiateNewPayroll: InitiateNewPayroll,
        deleteItem: deleteItem
    };
}]);
