angular
    .module('ustreet-app')
    .controller(
    "HeaderController",
    ['$scope', '$location', 'localStorageService', 'matchmedia', 'serviceHelperSvc',
        function ($scope, $location, localStorageService, matchmedia, serviceHelperSvc) {

            window.match_media = matchmedia;

            $scope.roles = [];

            $scope.isActive = function (path) {
                return $location.path().indexOf(path) >= 0;
            };

            $scope.init = function () {
                if (app.roles === undefined) {
                    app.roles = localStorageService.get('Roles');
                    app.username = localStorageService.get('UserName');

                    if (angular.isArray(app.roles) && app.roles.length > 0) {
                        app.authenticated = true;
                    }else {
                        $scope.logOff();
                    }
                }
                //;
            };

            $scope.logOff = function () {
                serviceHelperSvc.Logout.logOut();
                console.log(localStorageService);
                localStorageService.clearAll();
                $location.path('/Login');
            };

            $scope.UserName = function () {
                return app.username;
            };

            $scope.hasRole = function (role) {
                if (app.roles !== undefined && angular.isArray(app.roles)) {
                    return (app.roles.indexOf(role) >= 0);
                }
                return false;
            };

            app.hasRole = $scope.hasRole;

            $scope.init();

            $scope.toggleMenu = function () {
                $('.sidebar').sidebar('setting', {dimPage: true}).sidebar(
                    'toggle');
            };

            $scope.isComputer = $(window).width() > 1024;

        }]
);

$(document).on(
    'click', 'a.top-menu', function (e) {
        if ($(e.target).is('a')) {
            if ($(window).width() <= 1024) {
                $('.sidebar').sidebar('setting', {dimPage: true}).sidebar(
                    'toggle');
            }
        }
    }
);
