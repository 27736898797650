angular
    .module('ustreet-app')
    .controller(
    'RolesCtrl', ['$scope', 'roleSvc',
        function ($scope, roleSvc) {
            $scope.list = [];

            this.init = function () {
                $scope.list = roleSvc.getList();
            };

            this.init();
        }
    ]
);