angular
    .module('ustreet-app')
    .controller(
    'ProjectHistoryCtrl', ['$scope', 'paymentSvc', 'paymentCategorySvc', 'projectSvc', '$stateParams',
        function ($scope, paymentSvc, paymentCategorySvc, projectSvc, $stateParams) {
          $scope.history = projectSvc.getHistory($stateParams.id);
          $scope.history.$promise.then(function(data){
            $scope.balance = 0;
            for(var i = 0; i < $scope.history.length; i++){
              var hist = $scope.history[i];
              if(hist.ExpenseCategory.IsPayout){
                $scope.balance = $scope.balance + hist.Amount;
              }else if(hist.ExpenseCategory.IsPayout == false){
                $scope.balance = $scope.balance - hist.Amount;
              }
              hist.Balance = $scope.balance;
            }
          });

          $scope.project = projectSvc.getItem($stateParams.id);
        }
    ]
);
