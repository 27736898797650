app.directive('semanticDropdown', function () {
    return {
        restrict: "A",
        require: 'ngModel',
        replace: true,
        link: function (scope, element, attrs, ngModelCtrl) {
            var dPicker = element.dropdown({
                                                transition: 'drop'
                                          });
        }

    };
});

app.directive('semanticCheckbox', function () {
    return {
        restrict: "A",
        require: 'ngModel',
        replace: true,
        link: function (scope, element, attrs, ngModelCtrl) {
            var dPicker = element.checkbox();
        }

    };
});

app.directive('semanticPopup', function () {
    return {
        restrict: "A",
        require: 'ngModel',
        replace: true,
        link: function (scope, element, attrs, ngModelCtrl) {
            var dPicker = element.popup({
                                    inline   : true,
                                    hoverable: true,
                                    position : 'bottom left',
                                    delay: {
                                      show: 300,
                                      hide: 800
                                    }
                                  });
        }

    };
});


app.directive('semanticSticky', function () {
    return {
        restrict: "A",
        replace: true,
        link: function (scope, element, attrs, ngModelCtrl) {
            var dPicker = element.sticky( );
        }

    };
});
