angular
    .module('ustreet-app')
    .config(['$stateProvider', function($stateProvider) {
        $stateProvider.state('/Admin/Locations', {
            name: 'location-list',
            url: '/Admin/Locations',
            component: 'locationList'
        });
        $stateProvider.state('/Admin/Locations/Add', {
            name: 'add-location',
            url: '/Admin/Locations/Add',
            component: 'locationForm'
        });
        $stateProvider.state('/Admin/Locations/Edit', {
            url: '/Admin/Locations/Edit/:id',
            component: 'locationForm'
        });
        $stateProvider.state('/Admin/Locations/Days', {
            url: '/Admin/Locations/Days/:id',
            component: 'locationDay'
        });
    }]);