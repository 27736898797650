



angular
    .module('ustreet-app')
    .controller('PaymentCategoryCtrl', ['$scope', '$location', '$stateParams', 'paymentCategorySvc',
function ($scope, $location, $stateParams, paymentCategorySvc) {
    app.bindCrud($location, $scope, $stateParams, paymentCategorySvc, '/PaymentCategory');
}]);



