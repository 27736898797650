angular
    .module('ustreet-app')
    .controller(
    'ExpenseTypesCtrl', ['$scope', 'expenseTypeSvc',
        function ($scope, expenseTypeSvc) {
            $scope.list = [];

            this.init = function () {
                $scope.list = expenseTypeSvc.getList();
            };

            this.init();
        }
    ]
);