angular
    .module('ustreet-app')
    .controller('CashRevenueCtrl', ['$scope', '$location', '$http', '$window', '$stateParams', '$filter', 'serviceHelperSvc', function ($scope, $location, $http, $window, $stateParams, $filter, serviceHelperSvc) {

        $scope.locations = serviceHelperSvc.Location.query();

        //$scope.loading = true;

        $scope.filter = {
            startDate: $location.search().startDate,
            lastDate: $location.search().endDate,
            location: $location.search().location
        };

        $scope.ChangeParams = function () {
            if ($scope.filter.startDate !== undefined && $scope.filter.lastDate !== undefined && $scope.filter.location !== undefined) {
                $location.search('startDate', $filter('date')($scope.filter.startDate, 'MM-dd-yyyy'));
                $location.search('endDate', $filter('date')($scope.filter.lastDate, 'MM-dd-yyyy'));
                $location.search('location', $scope.filter.location);

                var filter = {
                    startDate: $location.search().startDate,
                    lastDate: $location.search().endDate,
                    location: $location.search().location
                };
                $scope.loading = true;
                $scope.rows = serviceHelperSvc.RevenueReport.get(filter);
                $scope.rows.$promise.then(function (data) {
                    $scope.loading = false;
                }).error(function (data) {
                    $scope.loading = false;
                });
            }
        };

        $scope.ExportExcel = function () {

            if ($scope.filter.startDate !== undefined && $scope.filter.lastDate !== undefined && $scope.filter.location !== undefined) {
                var filter = {
                    startDate: $filter('date')($scope.filter.startDate, 'MM-dd-yyyy'),
                    lastDate: $filter('date')($scope.filter.lastDate, 'MM-dd-yyyy'),
                    location: $scope.filter.location
                };
                var param = jQuery.param(filter);
                var url = config.apiurl + 'api/RevenueReport/Excel?' + param;
                $window.open(url, "_BLANK");
            }
        };

        setTimeout(function() {
            $scope.ChangeParams();
        }, 100);
    }]);
