angular
    .module('ustreet-app')
    .controller(
    'IncidentTypesCtrl', ['$scope', 'incidentTypeSvc',
        function ($scope, incidentTypeSvc) {
            $scope.list = [];

            this.init = function () {
                $scope.list = incidentTypeSvc.getList();
            };

            this.init();
        }
    ]
);