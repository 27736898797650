function PaymentCtrl($filter, $scope, $location, $stateParams, paymentSvc, paymentCategorySvc, projectSvc, userSvc) {

    $scope.ready = true;

    $scope.AddNewLineItem = function(){
        // this is a payroll.
        $scope.item.PayrollPayments.push(new PayrollLineItem());
    };

    $scope.save = function (item) {
        if(item.Id !== undefined){
            // this is an update
            paymentSvc.edit(item).$promise.then(function(data){
                $location.path("/Payments");
            });
            return;
        }
        paymentSvc.add(item).$promise
            .then(function (data) {
                $location.url('/Payments');
            });
    };

    $scope.onTypeChanged = function(){
        if($scope.item.Type === 'payroll'){
            $scope.item.PayrollPayments.clear();
            $scope.item.ProjectPayments.clear();
        }  else{
            $scope.projects =  $scope.projects.filter(function(p) {
                return p.IsActive === true;
            });
            //$scope.item.PayrollPayments.clear();
            $scope.item.ProjectPayments = [{}];
        }
    };

    $scope.OnCategoryChanged = function(value){
        $scope.CurrentCategory = _.findWhere($scope.paymentCategories, {Id: Number(value)});
    };

    $scope.item = {
        PayrollPayments: [],
        ProjectPayments: []
    };

    function initiateObjects(data){
        $scope.item = data;
        $scope.item.PaymentDate = $filter('date')(new Date($scope.item.PaymentDate), 'yyyy-MM-dd','utc');
        if($scope.item.PayrollPayments.length > 0){
            for(var i= 0; i< $scope.item.PayrollPayments.length; i++){
                $.extend($scope.item.PayrollPayments[i], new PayrollLineItem());
            }
        }
        if($scope.item.Type === 'project'){
            $scope.OnCategoryChanged($scope.item.ProjectPayments[0].ExpenseCategoryId);
        }
        $scope.ready = true;
    }

    $scope.init = function(){
        $scope.ready = false;
        $scope.projects = projectSvc.getList();
        $scope.paymentCategories = paymentCategorySvc.getList();
        $scope.employees = userSvc.getList();

        // scope - load the existing payment
        if(angular.isDefined($stateParams.id)){
            paymentSvc.getItem($stateParams.id).$promise.then(function(data){
                initiateObjects(data);
            });
        }else{
            $scope.ready = true;
        }
    };

    $scope.GeneratePayroll = function(){
        $scope.ready = false;
        // disable the button so that more than 1 payroll is not generated
        $scope.disableForm = true;
        //TODO: display a loading indicator

        // call the server side code
        // load the object and initiate it.
        paymentSvc.InitiateNewPayroll().$promise.then(function(data){
            // clear the ids and unecessary fields from current objects
            data.Id = undefined;
            data.Status = 'draft';
            data.Remarks = '';
            angular.forEach(data.PayrollPayments, function(p){
                p.Id = undefined;
                p.HoursWorked = 0;
                p.Remarks = "";
            });
            initiateObjects(data);
        });
        // re-enable form
        $scope.disableForm = false;
    };
    $scope.init();

}

angular
    .module('ustreet-app')
    .component('paymentForm', {
        bindings: 'paymentForm',
        controller: PaymentCtrl,
        templateUrl: '/Scripts/app/components/payment/partials/form.html'
    });

PaymentCtrl.$inject = ['$filter','$scope', '$location', '$stateParams', 'paymentSvc', 'paymentCategorySvc', 'projectSvc', 'userSvc']
