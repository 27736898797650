angular
    .module('ustreet-app')
    .controller('AuditReportCtrl', ['$location', '$scope', '$window', 'userSvc', 'serviceHelperSvc', '$filter',
        function ($location, $scope, $window, userSvc, serviceHelperSvc, $filter) {
            $scope.hasRole = app.hasRole;

            $scope.loading = true;

            $scope.filter = {
                startDate: $location.search().startDate,
                lastDate: $location.search().endDate
            };

            $scope.ParamChanged = function () {
                $scope.loading = true;

                if ($scope.filter.startDate !== undefined) {
                    $location.search('startDate', $filter('date')($scope.filter.startDate, 'MM-dd-yyyy'));
                    $location.search('endDate', $filter('date')($scope.filter.lastDate, 'MM-dd-yyyy'));
                }


                $scope.data = serviceHelperSvc.UnAuditedReports.get({
                    startDate: $location.search().startDate,
                    endDate: $location.search().endDate
                });

                $scope.data.$promise.then(function (data) {
                    $scope.total = data.reduce(function (s, f) {
                        return s + f.CashSales;
                    }, 0);
                    $scope.loading = false;
                }).error(function (error) {
                    $scope.loading = false;
                });


            };

            setTimeout(function() {
                $scope.ParamChanged();
            }, 20);


        }]);
