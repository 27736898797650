angular
    .module('ustreet-app')
    .controller(
    'LocationTypesCtrl', ['$scope', 'locationTypeSvc',
        function ($scope, locationTypeSvc) {
            $scope.list = [];

            this.init = function () {
                $scope.list = locationTypeSvc.getList();
            };

            this.init();
        }
    ]
);
