angular
    .module('ustreet-app')
    .controller('TicketHistoryCtrl', ['$scope', '$location', '$stateParams', 'ticketTypeSvc', 'serviceHelperSvc',
    function ($scope, $location, $stateParams, ticketTypeSvc, serviceHelperSvc) {

        function init(){
          $scope.ticketType = ticketTypeSvc.getItem($stateParams.id);
          $scope.list = serviceHelperSvc.TicketHistory.get({ id: $stateParams.id });
          $scope.list.$promise.then(function(){
            $scope.ready = true;
          });
        }
        if ($stateParams.id !== undefined) {
            init();
        }

        $scope.ConfirmPullBack = function(issue){
          $scope.currentIssue = issue;
          $scope.show_ticket_pull_back_modal = true;
        };

        $scope.CloseModal = function () {
          $scope.show_ticket_pull_back_modal = false;
        };

        $scope.hasZeroBalance = function(history){
            if(history === undefined || history.length == 0){
              return false;
            }

            var lastEntry = history[history.length - 1];
            return ((lastEntry.EndNumber - lastEntry.ClosingNumber) == 0);
        };


        $scope.ShowIssuedTicketStatus = function(issue){
          $scope.show_issued_ticket_history_dialog = true;
          $scope.issue_history_ready = false;
          $scope.currentIssue = issue;
            serviceHelperSvc
                .IssuedTicketHistory
                .get({id: issue.Id})
                .$promise
                .then(function(data){
                      $scope.IssuedHistory  = data.IssuedTicketHistory;
                      $scope.DeletedTickets = data.DeletedTicketHistory;
                      $scope.issue_history_ready = true;
                });
        };

        $scope.filterZeroOperations = function(item){
          return ($scope.showZero || item.Tickets > 0);
        };

        $scope.PullIssueBack = function(){
        	 var issue = $scope.currentIssue;
            var result = serviceHelperSvc.PullIssueBack.get({issuedTicketId: issue.Id});
            result.$promise.then(function(data){
              $scope.message = 'Your ticket has been pulled back successfully.';
              init();
              $scope.show_ticket_pull_back_modal = false;
            });
        };

    }]);
