function LocationsCtrl($scope, locationSvc, locationTypeSvc) {
    $scope.locations = [];

    $scope.ready = false;

    $scope.deleteLocation = function (locationId) {
        locationSvc.deleteLocation(locationId).$promise
            .then(
                function (data, responseHeaders) {
                    for (var i = $scope.locations.length - 1; i >= 0; i--) {
                        if ($scope.locations[i].Id === locationId) {
                            $scope.locations.splice(i, 1);
                            break;
                        }
                    }
                }
            );

    };
    this.init = function () {
        this.loadLocations();
    };
    this.loadLocations = function () {
        $scope.master_locations = locationSvc.getLocationsFull();
        $scope.master_locations.$promise.then(function (data) {
            $scope.ready = true;
        });
        $scope.locationTypes = locationTypeSvc.getList();
        $scope.FilterLocations();
    };

    $scope.FilterLocations = function () {
        if ($scope.LocationTypeId) {
            $scope.locations = _.filter(
                $scope.master_locations, function (l) {
                    return l.LocationTypeId === $scope.LocationTypeId;
                }
            );
        } else {
            $scope.locations = $scope.master_locations;

        }
    };

    this.init();
}

angular
    .module('ustreet-app')
    .component('locationList', {
        bindings: 'locationList',
        controller: LocationsCtrl,
        templateUrl: '/Scripts/app/components/admin/locations/partials/index.html'
    });

LocationsCtrl.$inject = ['$scope', 'locationSvc', 'locationTypeSvc'];
