angular
    .module('ustreet-app')
    .controller('CashManagementCtrl', ['$scope', '$anchorScroll' ,'$location', '$stateParams','$filter', 'serviceHelperSvc','dailyReportSvc','ticketTypeSvc','userSvc','paymentTypeSvc','expenseTypeSvc','adjustmentTypeSvc',
                                      function ($scope, $anchorScroll , $location, $stateParams,$filter, serviceHelperSvc, dailyReportSvc, ticketTypeSvc, userSvc, paymentTypeSvc, expenseTypeSvc, adjustmentTypeSvc) {

  $scope.TicketTypes = ticketTypeSvc.getList();
  $scope.Employees = userSvc.getList();
  $scope.PaymentTypes = paymentTypeSvc.getList();
  $scope.ExpenseTypes = expenseTypeSvc.getList();
  $scope.AdjustmentTypes = adjustmentTypeSvc.getList();

  $scope.parseFloat = parseFloat;
  $scope.isReady = false;


  $scope.hasRole = app.hasRole;
  $scope.$watch("filter.lastDate", function(){
    $scope.ChangeLastDate();
  });

  $scope.hasPermission = false;
  // default date should be today
  $scope.filter = {lastDate: $filter('date')(new Date(),'MM-dd-yyyy')};


  $scope.ChangeLastDate = function(){
   $scope.isReady = false;
    $scope.rows = [];
    let rows = serviceHelperSvc.Audits.get({date: $filter('date')($scope.filter.lastDate,'MM-dd-yyyy') });

    rows.$promise.then(function (data) {

          $scope.header = data[0];
            // remove the dummy header row.
          data.splice(0, 1);
          $scope.rows = data;
          $scope.isReady = true;
          $scope.hasPermission = true;

    }).catch(() => {
        $scope.hasPermission = false;
        $scope.isReady = true;
    });
  };

  $scope.ConfirmDay = function(day){
    day.IsSaving = true;
    serviceHelperSvc.SaveAudit.post(day).$promise.then(function(data){
      day.IsAudited = true;
      if(day.IsBeingEdited){
        day.IsBeingEdited = false;
        day.IsCorrected = (day.CashSales !== day.OldValue);
      }
      day.IsSaving = false;
    });
  };

  $scope.ShowDailyReport = function(id){
     $scope.current = dailyReportSvc.getItem(id).then(
        function(data){
            $scope.current = data;
            angular.forEach(data.Roasters, function (r) {
                r.StartTime = new Date(r.StartTime);
                r.EndTime = new Date(r.EndTime);
            });
            $scope.viewOnly = ($scope.current.Status !== 'Draft');
            $scope.show_daily_report_modal = true;
            //$location.hash('viewStart');
            $scope.ready = true;
            $anchorScroll();
        }
    );

    //TODO: remove this part
    // Actually, go ahead and move it to the model class
    $scope.TotalSales = function(){
      if($scope.current.DailySales === undefined) {
         return 0;
      }

      var total = 0;
      for(var i = 0; i < $scope.current.DailySales.length; i++){
          var recipt = $scope.current.DailySales[i];
          if(angular.isNumber(recipt.TicketCount)){
              total = total + (recipt.TicketCount * recipt.Rate);
          }
      }
      return total;
    };

    $scope.TotalCashSales = function () {

      if($scope.current.DailySales === undefined) {
         return 0;
      }

      var total = 0;
      for(var i = 0; i < $scope.current.DailySales.length; i++){
          var recipt = $scope.current.DailySales[i];
          //WARNING: Ticket type id is hardcoded here for cash
          if (angular.isNumber(recipt.TicketCount) && recipt.PaymentTypeId === 1) {
              total = total + (recipt.TicketCount * recipt.Rate);
          }
      }
      return total;
    };

    $scope.TotalExpenses = function(){
      if($scope.current.DailyExpenses === undefined) {
         return 0;
      }

      var total = 0;
      for(var i = 0; i < $scope.current.DailyExpenses.length; i++){
        var expense = $scope.current.DailyExpenses[i];
        total = total + (expense.Quantity * expense.UnitPrice);
      }
      return total;
    };
    // end of view daily report section

  };

}]);
