angular
    .module('ustreet-app')
    .controller('EmployeeReportCtrl', ['$location', '$scope', '$window', 'userSvc', 'serviceHelperSvc', '$filter',
        function ($location, $scope, $window, userSvc, serviceHelperSvc, $filter) {
            $scope.hasRole = app.hasRole;

            $scope.Employees = userSvc.getList();

            $scope.filter = {
                startDate: $location.search().startDate,
                lastDate: $location.search().endDate,
                employee: $location.search().employee
            };

            $scope.convertDate = function (date) {
                return new Date(date);
            };
            $scope.OnEmployeeChanged = function () {

                if ($scope.filter.employee) {
                    $location.search('startDate', $filter('date')($scope.filter.startDate, 'MM-dd-yyyy'));
                    $location.search('endDate', $filter('date')($scope.filter.lastDate, 'MM-dd-yyyy'));
                    $location.search('employee', $scope.filter.employee);
                }
                if ($location.search().employee) {
                    $scope.loading = true;
                    $scope.EmployeeRecords = serviceHelperSvc
                        .EmployeeReport
                        .get({
                            startDate: $location.search().startDate,
                            endDate: $location.search().endDate,
                            employeeId: $location.search().employee
                        });
                    $scope.EmployeeRecords.$promise.then(function (data) {
                        $scope.loading = false;
                    }).error(function (data) {
                        $scope.loading = false;
                    });

                }
            };


            $scope.ExportExcel = function () {


                var filter = {
                    startDate: $location.search().startDate,
                    endDate: $location.search().endDate,
                    employeeId: $location.search().employee
                };
                var param = jQuery.param(filter);
                var url = config.apiurl + 'api/Employee/Excel?' + param;
                $window.open(url, "_BLANK");
            };


            setTimeout(function () {
                $scope.OnEmployeeChanged();
            }, 100);
        }]);
