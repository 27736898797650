function PayrollLineItem(pay) {

    $.extend(this, pay);

    PayrollLineItem.prototype.init = function () {

    };

    PayrollLineItem.prototype.subTotalBeforeDeductions = function () {
        return (Number(this.Rate) * Number(this.HoursWorked));
    };

    PayrollLineItem.prototype.totalDue = function () {
        this.due = this.subTotalBeforeDeductions();
        if (!angular.isNumber(this.Deductions)) {
            this.Deductions = 0;
        }

        this.actualDue = (Number(this.Rate) * Number(this.HoursWorked)) - Number(this.Deductions);
        return this.actualDue;
    };
}
