angular
    .module('ustreet-app')
    .factory('ticketInventorySvc', ['$resource', 'serviceHelperSvc', function ($resource, serviceHelper) {
        var Service = serviceHelper.TicketInventory;
        var add = function (item) {
            //$resource.save will immediately return an object which will have $promise property.
            //This property will get resolved with values, once the Server returns response
            return Service.save(item);
        };
        var edit = function (item) {
            var updation = Service.update(item);
            return updation;
        };
        var getItem = function (id) {
            return Service.get({id: id});
        };

        var getReceiveDetail = function (id) {
            return serviceHelper.ReceiveDetail.get({id: id});
        };

        var updateReceiveDetail = function(item) {
            return serviceHelper.ReceiveDetail.update(item);
        };

        var getList = function () {
            return Service.query();
        };
        var deleteItem = function (id) {
            return Service.delete({id: id});
        };

        return {
            add: add,
            edit: edit,
            getItem: getItem,
            getList: getList,
            getReceiveDetail: getReceiveDetail,
            updateReceiveDetail: updateReceiveDetail,
            deleteItem: deleteItem
        };
    }]);