angular
    .module('ustreet-app')
    .controller('UsersCtrl', ['$scope', 'userSvc',
        function ($scope, locationSvc) {
            $scope.locations = [];
            
            $scope.deleteUser = function (locationId) {
                locationSvc.deleteUser(locationId).$promise
                .then(function (data, responseHeaders) {
                    for (var i = $scope.locations.length - 1; i >= 0 ; i--) {
                        if ($scope.locations[i].Id === locationId) {
                            $scope.locations.splice(i, 1);
                            break;
                        }
                    }
                });

            };
          
             $scope.toggleSelected = function(){
               
              };
            this.init = function () {
                this.loadUsers();
            };
            this.loadUsers = function () {
                $scope.users = locationSvc.getUsers();
            };
          
            this.init();
        }]);