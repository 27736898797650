angular
    .module('ustreet-app')
    .controller('HomeCtrl', ['$scope', '$filter', '$location', 'serviceHelperSvc', 'localStorageService',  '$http',
  function($scope, $filter, $location, serviceHelperSvc, localStorageService, $http) {

  app.roles = localStorageService.get('Roles');
  $http.defaults.headers.common.Authorization =  localStorageService.get('token');

  $scope.ready = true;
  if (app.change_password) {
    $location.path('/Profile');
  }


  $scope.topLocationsChart = {
    chart: {
      caption: "Top Locations " ,
      theme: 'ocean',
      slantlabels: "45"
    },
    data: []
  };

  $scope.sameDayIncome = {
    chart: {
      caption: 'Same Day Income Trend ' ,
      theme: 'ocean',
      slantlabels: "45"
    },
    data: []
  };

  $scope.paymentTypeSummaryChart = {
    chart: {
      caption: 'Payment Type Summary - Today ' ,
      showlabels: "0",
      showlegend: "1",
      enablemultislicing: "0",
      showpercentvalues: "1",
      showpercentintooltip: "0",
      plottooltext: "Payment Type : $label Daily Sales : $datavalue",
      showPercentInTooltip: "0",
      decimals: "1",
      useDataPlotColorForLabels: "1",
      theme: "fint"
    },
    data: []
  };

  $scope.hasRole = app.hasRole;

  function view(dateParam) {
    if(!$scope.hasRole('ADMIN')){
      return;
    }

    $scope.loading = true;
    var date = $filter('date')(dateParam, 'MM-dd-yyyy');
    $scope.summary = serviceHelperSvc.DReport.get({
      date: date
    });

    var d = [];
    $scope.summary.$promise.then(function() {
      for (var i = 0; $scope.summary.TopLocationByIncome !== undefined && $scope.summary.TopLocationByIncome.length > i && i < 10; i++) {
        var income = $scope.summary.TopLocationByIncome[i];
        d.push({
          label: income.LocationName,
          value: income.Amount,
          "color": "008ee4"
        });

      }
      $scope.topLocationsChart.data = d;
      $scope.topLocationsChart.chart.caption = 'Top Locations on ' + date;

      d = [];
      for (var index = 9; index >= 0 && $scope.summary.SameDayIncomeTrend !== undefined; index--) {
        var incomeObj = $scope.summary.SameDayIncomeTrend[index];
        d.push({
          label: $filter('date')(incomeObj.ReportDate, 'dd MMM'),
          value: incomeObj.Amount,
          "color": (index === 0)?"CC2222":"008ee4"
        });
      }

      var stringDate = new moment().subtract(1, 'days').format('dddd');
      if(moment(date).isValid()){
        stringDate = new moment(date).format('dddd');
      }
      $scope.sameDayIncome.chart.caption = 'Same Day Income Trend - (' + stringDate + ')';
      $scope.sameDayIncome.data = d;
      // do the part about payment ticketTypeSvc
      d = [];
      for(var index2 = 0; $scope.summary.PaymentTypeSummary !== undefined && index2 < $scope.summary.PaymentTypeSummary.length; index2++){
        var revenue = $scope.summary.PaymentTypeSummary[index2];
        d.push({label: revenue.Name, value: revenue.Revenue});
      }

      $scope.paymentTypeSummaryChart.data = d;
      $scope.paymentTypeSummaryChart.chart.caption = 'Payment type summary on ' + date;
      $scope.ready = true;
      $scope.loading = false;
    }, function (response) {
      console.log(response);
    });

  }

  $scope.$watch('filter.date', function(data) {
    if ($scope.filter && $scope.filter.date) {
      var dt = $filter('date')(new moment($scope.filter.date,"MM-DD-YYYY").toDate(), 'MM-dd-yyyy');
      view(dt);
    } else {
      $scope.filter = {date: $filter('date')(new moment().subtract(1, 'days').toDate(), 'MM-dd-yyyy')};
    }
  });

  $scope.ViewSummary = function() {
    view($scope.filter.date);
  };

  if (!app.home_loaded) {
    $('.sidebar').sidebar('setting', {
      dimPage: false
    }); //.sidebar('toggle');
    app.home_loaded = true;
  }

}]);
