angular.module('ustreet-app')
    .directive('calendar', function () {
        return {
            restrict: 'E',
            replace: true,
            transclude: true,
            scope: {
                changed: '&?',
                disabled: '&?',
                ngModel: '=ngModel'
            },
            controller: function () {
                var vm = this;
            },
            controllerAs: 'vm',
            bindToController: true,
            require: 'ngModel',
            template: '<div class="field">' +
                '        <div class="ui calendar" id="date_calendar" >\n' +
                '          <div class="ui input right icon">\n' +
                '            <i class="calendar icon"></i>\n' +
                '            <input type="text" placeholder="{{vm.ngModel}}">\n' +
                '          </div>\n' +
                '       </div>\n' +
                '    </div>',
            link: function (scope, element, attrs, ngModel) {

                scope.$watch(function () {
                    return ngModel.$modelValue;
                }, function (newValue) {
                    console.log('in watch');
                    console.log(newValue);
                    if (newValue !== undefined && newValue.toString().includes('T00:00:00')) {
                        ngModel.$setViewValue(moment(new Date(newValue), 'yyyy-MM-dd').format('yyyy-MM-DD'));
                        //scope.$apply();
                    }
                });

                element.calendar({
                    initialDate: scope.vm.ngModel,
                    date: scope.vm.ngModel,
                    type: 'date',
                    maxDate: new Date(),
                    onChange: function (val) {
                        ngModel.$setViewValue(val);
                        scope.$apply();
                    }
                });
            }
        };
    });

angular.module('ustreet-app')
    .directive('timePicker', function () {
        return {
            restrict: 'E',
            replace: true,
            transclude: true,
            scope: {
                changed: '&?',
                disabled: '&?',
                ngModel: '=ngModel'
            },
            controller: function () {
                var vm = this;
            },
            controllerAs: 'vm',
            bindToController: true,
            require: 'ngModel',
            template: '<div class="field">' +
                '   <div class="ui calendar" >\n' +
                '       <div class="ui input right icon">\n' +
                '           <i class="calendar icon"></i>\n' +
                '           <input type="text" width="100%" placeholder="{{vm.ngModel}}">\n' +
                '       </div>\n' +
                '   </div>' +
                '</div>',
            link: function (scope, element, attrs, ngModel) {

                element.calendar({
                    initialDate: scope.vm.ngModel,
                    type: 'time',
                    mode: 'hour',
                    startMode: 'hour',
                    date: scope.vm.ngModel,
                    maxDate: new Date(),
                    onChange: function (val) {
                        ngModel.$setViewValue(val);
                        scope.$apply();
                    }
                });
            }
        };
    });


angular.module('ustreet-app')
    .directive('fomCheckbox', function () {
        return {
            restrict: 'E',
            replace: true,
            transclude: true,
            scope: {
                changed: '&?',
                disabled: '&?',
                ngModel: '=ngModel'
            },
            controller: function () {
                var vm = this;
            },
            controllerAs: 'vm',
            bindToController: true,
            require: 'ngModel',
            template: '<div class="field">' +
                '   <div class="ui checkbox" >\n' +
                '       <div class="ui toggle checkbox">\n' +
                '           <input type="checkbox" width="100%" checked="{{vm.ngModel}}">\n' +
                '       </div>\n' +
                '   </div>' +
                '</div>',
            link: function (scope, element, attrs, ngModel) {

                element.checkbox({
                    onChange: function (val) {
                        ngModel.$setViewValue(val);
                        scope.$apply();
                    }
                });
            }
        };
    });