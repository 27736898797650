angular
    .module('ustreet-app')
    .controller(
    'PaymentTypesCtrl', ['$scope', 'paymentTypeSvc',
        function ($scope, paymentTypeSvc) {
            $scope.list = [];

            this.init = function () {
                $scope.list = paymentTypeSvc.getList();
            };

            this.init();
        }
    ]
);