angular
    .module('ustreet-app')
    .factory('authorizationInterceptor', ['$rootScope', '$q', '$location', function ($rootScope, $q, $location) {
    return {
        request: function(config){
            return config;
        } ,
        responseError: function (rejection) {
            switch (rejection.status) {
                case 0:
                case 401: {
                    $rootScope.$broadcast('logOff');
                    $location.url('/Login');
                    break;
                }
                case 403: {
                    //$location.url('/Login');
                    break;
                }
                default: {
                    break;
                }
            }

            return $q.reject(rejection);
        }
    };
}]);

//$httpProvider.interceptors.push('authorizationInterceptor');


// todo: move this to somewhere else where it makes sense
Array.prototype.sum = function (prop) {
    var total = 0;
    for ( var i = 0, _len = this.length; i < _len; i++ ) {
        total += this[i][prop];
    }
    return total;
};

Array.prototype.sum2 = function (prop, prop2) {
    var total = 0;
    var _len = this.length;
    for ( var i = 0; i < _len; i++ ) {
        total += parseFloat(this[i][prop][prop2]);
    }
    return total;
};

Array.prototype.sum3 = function (prop, prop2) {
    var total = 0;
    var _len = this.length;
    for ( var i = 0; i < _len; i++ ) {
        total += parseFloat(this[i][prop].sum(prop2));
    }
    return total;
};

Array.prototype.contains = function(obj) {
    var i = this.length;
    while (i--) {
        if (this[i] === obj) {
            return true;
        }
    }
    return false;
};


Date.toLocalDate = function(d){
  return moment.utc(d).local().toDate();
};
