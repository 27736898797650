angular
    .module('ustreet-app')
    .controller('ScheduleCreateCtrl', ['$scope', 'scheduleService', '$stateParams', '$filter', function ($scope, scheduleService, $stateParams, $filter) {

  $scope.defaultLocationStartTime = new moment({hour: 5});

  $scope.GetDateObject = function(d){
    return Date.toLocalDate(d);
  };

  $scope.clearMessage = function(){
    $scope.message = undefined;
  };


  function initDatesForDays(days){
    angular.forEach(days, function(d){
          d.StartTime = Date.toLocalDate(d.StartTime);
        });
  }

  function initAllDates(){
    angular.forEach($scope.employees, function(e){
      initDatesForDays(e.Day1);
      initDatesForDays(e.Day2);
      initDatesForDays(e.Day3);
      initDatesForDays(e.Day4);
      initDatesForDays(e.Day5);
      initDatesForDays(e.Day6);
      initDatesForDays(e.Day7);
    });
  }

  //load things for the view mode
  $scope.weeks = scheduleService.Weeks.get();

  $scope.LoadWeek = function(){
    $scope.ready = false;
    $scope.loading = true;
    scheduleService.ByEmployee.get({id: $scope.selected.week})
      .$promise.then(function(d){
        $scope.employees = d.employees;
        initAllDates();
        $scope.locations = d.locations;
        $scope.ready = true;
        $scope.loading = fasle;
      });
  };

  $scope.days = [];
  $scope.days[0] = 'Sun';
  $scope.days[1] = 'Mon';
  $scope.days[2] = 'Tue';
  $scope.days[3] = 'Wed';
  $scope.days[4] = 'Thu';
  $scope.days[5] = 'Fri';
  $scope.days[6] = 'Sat';


  $scope.CreateOpen = function(){
    $scope.clearMessage();
    $scope._date = $filter('date')(Date.toLocalDate($scope.WeekOf), 'MM-dd-yyyy');
    var schedule = scheduleService.Schedule.create({date: $scope._date});



    schedule.$promise.then(function(data){
      $scope.weekly = data;
      scheduleService.ByEmployee.get({id: data.Id})
            .$promise.then(function(d){
              $scope.employees = d.employees;
              initAllDates();

              $scope.locations = d.locations;

              $scope.Id = d.Id;

              //index locations
              $scope.locationIndex = [];
              for(var i=0; i<$scope.locations.length; i++){
                var loc = $scope.locations[i];
                $scope.locationIndex[loc.Id] = loc;
              }
            });
    });

  };

  $scope.Finalize = function(){
    var finalize = scheduleService.Finalizer.finalize({id:$scope.weekly.Id, status: 'Final'});
    finalize.$promise.then(function(data){
      $scope.message  = 'Schedule has been finalized';
      $scope.weekly.status = 'Final';
    });
  };

  $scope.Save = function(){
    var save = scheduleService.Schedule.save({WeekOf:$scope._date, DeleteList: $scope.delete_list, Details: $scope.employees, Id : $scope.Id});
    save.$promise.then(function(d){
      $scope.message = d.message;
    });
  };

  /***
    Start of interactivity region
    Code that follows here is for interactive features like dragging and dropping etc.
  */
  $scope.OnDragStart = function(data, emp, day, index){
    $scope.dLocation = data;
    $scope.dragged_employee = emp;
    $scope.dragged_day = day;
    $scope.dragged_index = index;

  };

  $scope.OnDragSuccess = function(data){
    $scope.dLocation = undefined;
  };

  $scope.OnDropSuccess = function(loc, event, emp, field, index){
    $scope.clearMessage();

    if(_.isUndefined(emp[field]) || _.isNull(emp[field])){
      emp[field] = [];
    }

    if(_.isNull(emp.days)){
      emp.days = 0;
    }

    if(!loc.WorkingDays.contains(index) || !emp.WorkingDays.contains(index)){
        return;
    }
    // check if this location works on the date droped
    if(loc.WorkingDays.contains(index)){
      //check for duplicate
      for(var i = 0; i < emp[field].length; i++){

        if(emp[field][i].Id === loc.Id){
          return;
        }
      }
      loc = angular.copy(loc);


      var workingDayDetail= _.findWhere(loc.WorkingDayDetails, {'WorkingDayId' : index});
      var locationWorkingTime = Date.parse($filter('date')(Date.toLocalDate($scope.weekly.Dates[index]), 'MMMM dd, yyyy') + ' ' + $filter('date')(Date.toLocalDate(workingDayDetail.StartTime),'hh:mm a'));
      //var employeeWorkingTime = Date.toLocalDate(_.findWhere(emp.WorkingDayDetails, {'WorkingDayId' : index}).StartTime);
      loc.StartTime = Date.toLocalDate(locationWorkingTime);

      loc.Repeats = !$scope.norepeat;
      emp[field].push(loc);

      var location = $scope.locationIndex[loc.Id];
      // register this too
      if(_.isNull(location.emps)){
        location.emps  = [];
      }

      if(_.isNull(location.emps[index]) || _.isUndefined( location.emps[index] )){
          location.emps[index] = 0;
      }

      if(_.isNull(emp.locs) || _.isUndefined( emp.locs )){
        emp.locs = [];
      }

      if(_.isNull(emp.locs[index]) || _.isUndefined(emp.locs[index]) ){
        emp.locs[index] = [];
      }

      emp.locs[index].push(loc);

      location.emps[index]++;
      if(emp.locs[index].length === 1 ){
        emp.days ++;
      }
    }
    $scope.dLocation = undefined;
  };

  $scope.OnRemoveDropSuccess = function(loc){
    $scope.clearMessage();

    if($scope.dragged_employee === undefined || $scope.dragged_day === undefined ) {
      return;
    }

    if(_.isUndefined($scope.delete_list)){
      $scope.delete_list = [];
    }

    if(loc.WeeklyScheduleId !== undefined){
      $scope.delete_list.push( loc.WeeklyScheduleId );
    }

    var emp = $scope.dragged_employee;
    var index = $scope.dragged_index;
    var days = $scope.dragged_day;

    $scope.locationIndex[loc.Id].emps[index]--;
    days.splice(0,1);
    emp.days --;

 };

  $scope.StartTimeTooltip = function(locSchedule, employee, dayIndex){
    // find location's start time
    var location = $scope.locationIndex[locSchedule.Id];


    var locationDayPreference = _.findWhere(location.WorkingDayDetails, {'WorkingDayId': dayIndex});
    if(locationDayPreference == undefined){
      return;
    }

    var locationStartTime = locationDayPreference.StartTime;

    var employeePreferanceForDate =  _.findWhere(employee.WorkingDayDetails, {'WorkingDayId': dayIndex});
    // find employee's start time

    var employeeStartTime = (employeePreferanceForDate != undefined)? employeePreferanceForDate.StartTime : null;

    var employeeStartText = ((employeeStartTime == null) ? 'un specified' : $filter('date')(Date.toLocalDate(employeeStartTime), 'hh:mm a'));
    var result = 'Employee wants to start : ' + employeeStartText + '\n';
    result = result + 'Location Starts at : ' + $filter('date')(Date.toLocalDate(locationStartTime), 'hh:mm a') + '\n';
    result = result + 'Current schedule : ' + $filter('date')(Date.toLocalDate(locSchedule.StartTime), 'hh:mm a') + '\n' ;

    return result;
  };

  $scope.StartTimeValid =  function(locSchedule, employee, dayIndex){
    // find location's start time

    var location = $scope.locationIndex[locSchedule.Id];

    var locationDaySetting = _.findWhere(location.WorkingDayDetails, {'WorkingDayId': dayIndex});
    if(!locationDaySetting){
      return false;
    }

    var locationStartTime = Date.toLocalDate(locationDaySetting.StartTime);
    // normalize this date the hard way. god will defintely forgive you today.
    var locStartTimeString =  $filter('date')(Date.toLocalDate(locSchedule.StartTime), 'MMMM dd, yyyy') + ' ' + $filter('date')(locationStartTime, 'hh:mm a') ;

    locationStartTime = Date.parse(locStartTimeString);
    // find employee's start time
    var employeeWorkingDay = _.findWhere(employee.WorkingDayDetails, { 'WorkingDayId': dayIndex });

    var employeeStartTime = (employeeWorkingDay)? employeeWorkingDay.StartTime : locSchedule.StartTime;

    var employeeStartTimeString =  $filter('date')(Date.toLocalDate(locSchedule.StartTime), 'MMMM dd, yyyy') + ' ' + $filter('date')(Date.toLocalDate(employeeStartTime), 'hh:mm a') ;
    employeeStartTime = Date.parse(employeeStartTimeString);

    var currentStartTime =  $filter('date')(Date.toLocalDate(locSchedule.StartTime), 'MMMM dd, yyyy hh:mm a');
    currentStartTime = Date.parse(currentStartTime);

    if(employeeStartTime > currentStartTime || locationStartTime > currentStartTime){
      return false;
    }
    return true;
  };

  $scope.ValidateAllAssignments = function(assignments){
    if( assignments === undefined || assignments.length <= 1){
      return false;
    }
    var timeDiff = (Date.toLocalDate(assignments[0].StartTime) -  Date.toLocalDate(assignments[1].StartTime)) /  36e5;

    if(timeDiff < 4){
      return true;
    }
    return false;
  };

  /***
  End of interactive region.
  */

  // mobile region starts here.

  $scope.OnLocationChanged = function(){
    var schedule =  scheduleService.MyLocation.get( {locationId: $scope.Selected.Location, weeklyScheduleId: $scope.weekly.Id});
    schedule.$promise.then(function(data){
      $scope.rows = data.rows;
      angular.forEach(data.rows, function(d){
        d.WorkDay = $filter('date')(d.StartTime, 'dd MMM, yy');
      });
      $scope.dailySchedules = _.groupBy(data.rows, 'WorkDay');
    });
  };

  $scope.OnEmployeeChanged = function(){
    //var schedule =  scheduleService.MySchedule.GetDetails( $scope.Selected.Employee, $scope.weekly.Id );
    var schedule =  scheduleService.MyEmployee.get( {userId: $scope.Selected.Employee, weeklyScheduleId: $scope.weekly.Id});
    schedule.$promise.then(function(data){
      $scope.rows = data.rows;
      angular.forEach(data.rows, function(d){
        d.WorkDay = $filter('date')(d.StartTime, 'dd MMM, yy');
      });
      $scope.dailySchedules = _.groupBy(data.rows, 'WorkDay');
    });

  };

  // mobile region ends here

}]);


$('.ui.popup')
  .popup()
;
