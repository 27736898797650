angular
    .module('ustreet-app')
    .controller(
        'UsersCtrl', ['$scope', 'userSvc', 'serviceHelperSvc', 'roleSvc',
            function ($scope, userSvc, serviceHelperSvc,roleSvc) {
                $scope.list = [];
                $scope.roles = [];
                $scope.search = '';
                $scope.notfy = undefined;
                $scope.confirmation = undefined;
                $scope.ready = false;


                $scope.showAll = false;

                $scope.searchUser = function () {
                    let list = $scope.allList;
                    if (!$scope.showAll) {
                        list = _.where($scope.allList, {IsActive: true});
                    }
                    if($scope.role !== '' && $scope.role !== undefined){
                        list = list.filter((item) => {
                            return item.RoleKeys.includes($scope.role);
                        });
                    }
                    if ($scope.search !== '') {
                        const search = $scope.search.toLowerCase();
                        list = list.filter(function (user) {
                            return (user.FirstName.toLowerCase().includes(search) || user.LastName.toLowerCase().includes(search));
                        });
                    }
                    $scope.list = list;
                    $scope.ready = true;
                };

                $scope.toggleSelected = function () {
                    for (const element of $scope.list) {
                        element.IsSelected = $scope.toggle;
                    }
                };

                $scope.sendMessage = function () {
                    const users = $scope.list.filter((user) => user.IsSelected).map((user) => user.PhoneNumber);


                    if (users.length === 0) {
                        $scope.notify = ("Please select a few users before you click send");
                        return;
                    }

                    const message = {
                        Message: $scope.message.Text,
                        Numbers: users
                    };

                    const onSuccess = function () {
                        $scope.confirmation = "Message sent";
                        $scope.SelectiveGroup = false;
                    };

                    const onFailure = function () {
                        $scope.notify = "Error sending";
                    };

                    serviceHelperSvc
                        .Message.send(message)
                        .$promise.then(onSuccess, onFailure);
                };

                this.init = function () {
                    $scope.allList = userSvc.getList();
                    $scope.allList.$promise.then(function () {
                        $scope.allList.forEach(function (user) {
                           if(user.Roles){
                               user.RoleKeys = user.Roles.map(function (role) {
                                   return role.Key;
                               });
                           }
                        });
                        $scope.searchUser();
                    });

                    $scope.roles = roleSvc.getList();
                };

                this.init();
            }
        ]
    );
