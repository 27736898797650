angular
    .module('ustreet-app')
    .factory('locationReportSvc', ['$resource', 'serviceHelperSvc', function ($resource, serviceHelper) {
    var service = serviceHelper.LocationReport;
    
    var getItem = function (id) {
        return service.get({ id: id });
    };
    var getList = function () {
        return service.query();
    };
    return {
        getItem: getItem,
        getList: getList
    };
}]);