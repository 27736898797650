angular
    .module('ustreet-app')
    .controller('DailyCollectionCtrl', ['$scope', '$location', '$http', '$window', '$stateParams', '$filter', 'serviceHelperSvc', function ($scope, $location, $http, $window, $stateParams, $filter, serviceHelperSvc) {

        $scope.locations = serviceHelperSvc.Location.query();

        $scope.loading = true;

        $scope.filter = {
            startDate: $location.search().startDate,
        };

        $scope.ChangeParams = function () {
            console.log('Clicked view button');
            if ($scope.filter.startDate !== undefined) {
                $location.search('startDate', $filter('date')($scope.filter.startDate, 'MM-dd-yyyy'));
                var filter = {
                    forDate: $location.search().startDate
                };
                $scope.rows = serviceHelperSvc.DailyCollectionReport.get(filter);
            }
        };

        $scope.ExportExcel = function () {
            console.log('In Export Excel');
            if ($scope.filter.startDate !== undefined) {
                console.log('In Export Excel');
                var filter = {
                    forDate: $filter('date')($scope.filter.startDate, 'MM-dd-yyyy')
                };
                var param = jQuery.param(filter);
                var url = config.apiurl + 'api/DailyCollectionReport/Excel?' + param;
                $window.open(url, "_BLANK");
            }
        };

        setTimeout(function() {
            $scope.ChangeParams();
        }, 100);


    }]);
